import React, { useEffect, useState } from "react";
import "./sessao-banner.scss";
import { Banners } from "../../models/banners";
import { GestorRequisicoes } from "../../services/gestor";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import { Carousel } from "react-bootstrap";

const SessaoBanner: React.FC<any> = () => {
  const [index, setIndex] = useState(0);
  const [banners, setBanners] = useState<Banners>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await GestorRequisicoes.getBanners();
      setBanners(response);
      
      setTimeout(() => {
        setIndex(1); 
      }, 7000);
    };

    fetchData();
  }, []);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  }

  return (
    <section id="banner">
      <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        <Carousel interval={7000} activeIndex={index} onSelect={handleSelect}>
          {banners?.dados ? banners?.dados?.map((banner, index) => {
            return (
              <Carousel.Item key={index}>
                <div className="imgBanner">
                  <img
                    className="d-block w-100"
                    src={banner.image}
                    alt={`Slide ${index}`}
                  />
                </div>
              </Carousel.Item>
            );
          }) : <Skeleton width={"100%"} className="loadingBanner" />}
        </Carousel> 
      </SkeletonTheme>
    </section>
  );
}

export default SessaoBanner;
