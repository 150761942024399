
function convertDataToDataAmigavel(dataASerConvertida?: string) {
  const messesDoAno: Array<string> = ['', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
  const data = new Date(dataASerConvertida as string);

  return `${data.getDate()} de ${messesDoAno[data.getMonth()]} de ${data.getFullYear()}`;
}

function apiWhatsapp(numero: string, mensagem?: string) {
  return `https://api.whatsapp.com/send/?phone=55${numero.replace("-", "").replace("(", "").replace(")", "")}&text${mensagem ? mensagem : ""}&type=phone_number&app_absent=0`
}

function urlNormalize(caminho:string){
  return caminho.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(" ", "-");
}

function mascararTelefone (tel: string) {
  var telMasked = tel.replace(/[^\d]/g, '')
  if (telMasked.length <= 9)
    telMasked = telMasked.replace(/(\d{2})(\d)/, "($1) $2");
  else
    telMasked = telMasked.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/, "($1) $2-$3");

  if (telMasked.length > 15) telMasked = telMasked.substring(0, 15);
  return telMasked;
}

function validaTelefone(numero?: string) {
  const regex = new RegExp('^\\(?[0-9]{2}\\)? ?9?[0-9]{4}-?[0-9]{4}$');

  if (regex.test(numero as string)) return true;

  return false;
}


function validaEmail(email?: string) {

  var regex = new RegExp('^[^@\\s]+@[a-zA-Z0-9]+(\\.[a-zA-Z]+)+$')
  // const regex = new RegExp('^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$');

  if (regex.test(email?.toLowerCase() as string)) return true;

  return false;
}

function validaCnpj(cnpj: string) {
  return /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/.test(cnpj);
}

function validateCPF(cpf: string){
  return /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(cpf);
}

function validateCPForCNPJ(value: string) {
  return value.length <= 14 ? validateCPF(value) :  validaCnpj(value);
}

function mascararCnpj(cnpj: string) {
  let mascaraCnpj = cnpj.replace(/[^\d]/g, '')

  if (mascaraCnpj.length > 14) mascaraCnpj = mascaraCnpj.substring(0, 14);

  mascaraCnpj = mascaraCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")

  return mascaraCnpj;
}

function mascararCPF(cpf: string) {
  let mascaraCpf = cpf.replace(/[^\d]/g, '')

  if (mascaraCpf.length > 14) mascaraCpf = mascaraCpf.substring(0, 14);

  mascaraCpf = mascaraCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")

  return mascaraCpf;
}

function mascararCPForCNPJ(value: string){
  const cleanedValue = value.replace(/[^\d]/g, '')
  return value.length <= 11 ? mascararCPF(cleanedValue) : mascararCnpj(cleanedValue);
}

export const FuncoesAuxilizares = {
  convertDataToDataAmigavel,
  apiWhatsapp,
  urlNormalize,
  validaEmail,
  validaCnpj,
  mascararCnpj,
  validaTelefone,
  mascararTelefone,
  validateCPForCNPJ,
  mascararCPForCNPJ
}