import { type } from "os";
import { IDeserializable } from "./deserealizable";

export type ContatoType = {
  email?: string;
  nome?: string;
  cidade?: string;
  uf?: string;
  cnpj?:string;
  assunto?: string;
  mensagem?: string;
  telefone?: string;
  captcha?: string;
}

export enum ContatoStatus{
  OK = 200,
  ERROR = 400
}