import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../utilits/tittle";
import "./conteudo-nao-encontrado.scss"

export default function ConteudoNaoEncontrado() {
  window.scroll(0, 0);
  useTitle("Página não encontrada")
  return (
    <section id="PaginaConteudoNaoEncontrado">
     <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 conteudo">
            <h2 className="erro">404</h2>
            <h2>Página não encontrada</h2>
            <Link to="/">
              <button className="btn btn-info botao">
                Voltar para página inicial
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}