export type GenericContactType = {
  to?: string;  // se undefined, backend pegará email cadastrado da empresa
  captcha: string,
  subject: string,
  emailBody: Object 
}

export enum GenerictContactStatus{
  OK = 200,
  ERROR = 400
}