import { Component } from "react";
import { ProdutoServicoType } from "../../models/produtos-servicos";
import { GestorRequisicoes } from "../../services/gestor";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import seta from "../../images/icons/seta.svg";
import "./sessao-listagem-produtos.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import { CardProduto } from "../personalizados/card-produto/card-produto";

export default class SessaoListagemProdutos extends Component {
  state: {
    loading: boolean;
    configuracao?: {
      listagemTotal?: boolean;
      listagemCarousel?: boolean;
    };
    produtos: Array<ProdutoServicoType>;
  } = {
      loading: true,
      produtos: [],
    };

  montaProdutos(
    produtos: Array<ProdutoServicoType>,
    montarParaCarousel?: boolean
  ) {
    const retorno: Array<JSX.Element> = [];

    produtos.forEach((produto, index) => {
      let imagemPrincipal = "";

      produto.imagensArray?.forEach((imagem) => {
        if (imagem.showFirst) {
          imagemPrincipal = imagem.src as string;
        }
      });

      retorno.push(
        <Col className="containerCardProduto" key={index} lg={4}>
          <CardProduto
            img={
              imagemPrincipal
                ? imagemPrincipal ?? ""
                : produto.imagensArray?.at(0)?.src ?? ""
            }
            link={`/produtos/${FuncoesAuxilizares.urlNormalize(
              produto.category as string
            )}/${produto.id}/${FuncoesAuxilizares.urlNormalize(
              produto.title as string
            )}`}
            titulo={produto.title ?? ""}
            verProduto
            sombraMobile={!montarParaCarousel}
          />
        </Col>
      );
    });

    return retorno;
  }

  async getCategoriaProdutoId(urlNormalizada: string): Promise<number | null> {
    const response = await GestorRequisicoes.getCategoriasProduto();

    if (response.dados) {
      var categoria =
        response.dados.filter(
          (categoria) =>
            FuncoesAuxilizares.urlNormalize(categoria.title ?? "") ===
            urlNormalizada
        )?.[0] ?? null;

      return categoria?.id ?? null;
    }
    return null;
  }

  async componentDidMount() {
    const listaDeCaminhosUrl = window.location.pathname.split("/");

    var idCategoria = await this.getCategoriaProdutoId(listaDeCaminhosUrl[2]);

    if (idCategoria) {
      GestorRequisicoes.getProdutosServicosPorCategoria(idCategoria).then(
        (dados) => {
          const idCaracteristicas: Array<number> = [];

          dados.data?.map(({ characteristics_product_services }) => {
            const { id } = characteristics_product_services?.at(0)
              ?.characteristics_descriptions ?? { id: undefined };

            if (id !== undefined && idCaracteristicas.indexOf(id) === -1)
              idCaracteristicas.push(id);
          });

          if (idCaracteristicas.length) {

            const [sw, ulp, lp] = idCaracteristicas;
            idCaracteristicas[0] = ulp;
            idCaracteristicas[1] = lp;
            idCaracteristicas[2] = sw;

            this.state.produtos = [];

            let produtosAgrupadosPorCaracteristicas: ProdutoServicoType[] = []

            for (let id of idCaracteristicas){
              const agrupamento = dados.data?.filter((item) => {
                return (
                  item.characteristics_product_services?.at(0)
                    ?.characteristics_descriptions.id === id
                );
              }) ?? []
              produtosAgrupadosPorCaracteristicas = produtosAgrupadosPorCaracteristicas.concat(agrupamento);
            }

            const produtosSemCaracteristicas = dados.data?.filter((p) => !(p.characteristics_product_services?.at(0))) ?? [];

            produtosAgrupadosPorCaracteristicas = produtosAgrupadosPorCaracteristicas.concat(produtosSemCaracteristicas);

            this.state.produtos = produtosAgrupadosPorCaracteristicas
          } else this.state.produtos = [...(dados?.data ?? [])];

          this.state.loading = false;
          this.setState({ ...this.state });
        }
      );
    } else {
      this.state.loading = false;
      this.setState({ ...this.state });
    }

    const params = new URLSearchParams(window.location.search);

    if (params.has("id")) {
      this.state.configuracao = {
        listagemTotal: false,
        listagemCarousel: true,
      };
      this.setState(this.state);

      return;
    }

    this.state.configuracao = {
      listagemTotal: true,
      listagemCarousel: false,
    };
    this.setState(this.state);

    return;
  }

  render() {
    //Configuração para o carrosel
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    if (!this.state.loading && !this.state.produtos.at(0)) {
      return (
        <div className="row justify-content-center linha">
          <div className="col-lg-8 conteudo">
            <h2>Produto não encontrado!</h2>
            <Link to="/">
              <button className="btn btn-info botao">
                Voltar para página inicial
              </button>
            </Link>
          </div>
        </div>
      );
    }

    return !this.state.loading ? (
      <section id="SessaoListagemProdutos">
        <Container>
          {this.state.configuracao?.listagemCarousel ? (
            <Row>
              <Col>
                <Carousel
                  responsive={responsive}
                  removeArrowOnDeviceType={["mobile", "tablet"]}
                  showDots={true}
                  dotListClass={"dotMenosDe" + this.state.produtos?.length}
                >
                  {this.montaProdutos(this.state.produtos, true)}
                </Carousel>
              </Col>
            </Row>
          ) : null}

          {this.state.configuracao?.listagemTotal ? (
            <Row>{this.montaProdutos(this.state.produtos)}</Row>
          ) : null}
        </Container>
      </section>
    ) : (
      <SkeletonTheme
        baseColor={COLORS.skeletonBaseColor}
        highlightColor={COLORS.skeletonHighlightColor}
      >
        <section id="SessaoListagemProdutos">
          <Container className="containerLoading">
            <Row>
              <Col lg={4}>
                <Skeleton className="cardLoading" width="100%"></Skeleton>
              </Col>
              <Col lg={4}>
                <Skeleton className="cardLoading" width="100%"></Skeleton>
              </Col>
              <Col lg={4}>
                <Skeleton className="cardLoading" width="100%"></Skeleton>
              </Col>
            </Row>
          </Container>
        </section>
      </SkeletonTheme>
    );
  }
}
