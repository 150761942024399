import React from "react";
import { Outlet } from "react-router-dom";
import SessaoBannerInterno from "../../components/sessao-banner-interno/sessao-banner-interno";
import SessaoProdutos from "../../components/sessao-produtos/sessao-produtos";
import { useTitle } from "../../utilits/tittle";

import "./produtos.scss"

export default function Produtos() {
  window.scroll(0, 0);
  useTitle("Produtos")
  return (
    <section id="Produtos">
      <SessaoBannerInterno path="Produtos" />
      <Outlet />
    </section>
  );
} 