import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import SessaoHeader from "./components/sessao-header/sessao-header";
import SessaoFooter from "./components/sessao-footer/sessao-footer";
import SessaoCopyright from "./components/sessao-copyright/sessao-copyright";
import Home from "./pages/home/home";
import Sobre from "./pages/sobre/sobre";
import Blog from "./pages/blog/blog";
import Contato from "./pages/contato/contato";
import Produtos from "./pages/produtos/produtos";
import SessaoPost from "./components/sessao-post/sessao-post";
import SessaoBlog from "./components/sessao-blog/sessao-blog";
import SessaoProdutos from "./components/sessao-produtos/sessao-produtos";
import SessaoProdutoInterno from "./components/sessao-produto-interno/sessao-produto-interno";
import SessaoListagemProdutos from "./components/sessao-listagem-produtos/sessao-listagem-produtos";
import { GestorRequisicoes } from "./services/gestor";
import ReactGA from "react-ga4";
import { configAnalytics } from "./utilits/analytics";
import TagManager from "react-gtm-module";
import ConteudoNaoEncontrado from "./pages/pagina-nao-encontrada/conteudo-nao-encontrado";
import SessaoBaixarFichaTecnica from "./components/sessao-baixar-ficha-tecnica/baixar-ficha-tecnica";
import "./fonts/VarelaRound-Regular.ttf";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const TrackingRoute = () => {
  const location = useLocation();
  const [trackingId, setTrackingId] = useState<string | undefined>();

  useEffect(() => {
    GestorRequisicoes.getInformacoes().then((response) => {
      if (!response.ga_tracking_id) return;

      const ok = configAnalytics(response.ga_tracking_id);
      if (ok) setTrackingId(response.ga_tracking_id);
    });
  }, []);

  useEffect(() => {
    if (trackingId) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, [location]);

  return <></>;
};

const tagManagerArgs = {
  gtmId: "GTM-P3NTLXW",
};

TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <Router>
      <SessaoHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quem-somos" element={<Sobre />} />
        <Route path="/blog" element={<Blog />}>
          <Route path="" element={<SessaoBlog />} />
          <Route
            path="post/*"
            element={[<SessaoPost key={0} />, <SessaoBlog key={1} />]}
          />
        </Route>
        <Route path="/contato" element={<Contato />} />
        <Route path="/produtos" element={<Produtos />}>
          <Route path="" element={<SessaoProdutos />} />
          <Route
            path="*"
            element={[
              <SessaoProdutoInterno key={0} />,
              <SessaoListagemProdutos key={1} />,
            ]}
          />
        </Route>
        <Route path="*" element={<ConteudoNaoEncontrado />} />
      </Routes>
      <SessaoBaixarFichaTecnica />
      <SessaoFooter />
      <SessaoCopyright />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
