import React from "react";
import SessaoBannerInterno from "../../components/sessao-banner-interno/sessao-banner-interno";
import SessaoContato from "../../components/sessao-contato/sessao-contato";
import SessaoMaps from "../../components/sessao-maps/sessao-maps";
import { useTitle } from "../../utilits/tittle";

import "./contato.scss"

export default function Contato() {
  window.scroll(0, 0);
  useTitle("Contato")
  return (
    <section id="Contato">
      <SessaoBannerInterno path="Contato" />
      <SessaoContato />
      <SessaoMaps />
    </section>
  );
} 