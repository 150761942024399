import { IDeserializable } from "./deserealize";

export type SocialMediaType = {
    id?: number,
    name?: string,
    icon?: string,
    link?: string,
    disabled?: boolean,
    createdAt?: string,
    updatedAt?: string,
    company?: number
}

export default class InformacoesModel implements IDeserializable {

  id?: number;
  state?: string;
  city?: string;
  name?: string;
  image?: string;
  address?: string;
  cep?: string;
  number?: number;
  neighborhood?: string;
  color1?: string;
  color2?: string;
  color3?: string;
  linkMaps?: string;
  whatsapp?: string;
  disabled?: boolean;
  email?: string;
  website_link?: string;
  complement?: string;
  watermarkError?: boolean;
  captcha_site_key?: string;
  ga_tracking_id?: string;
  working_hours?: string;
  removed?: boolean;
  createdAt?: string;
  updatedAt?: string;
  imageError?: boolean;
  product_service_height?: number;
  product_service_width?: number;
  should_resize_product_service?: boolean
  phones?: Array<
    {
      phone?: string
    }
  >
  social_media?: Array<SocialMediaType>;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}