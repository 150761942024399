import axios from "axios";
import BlogsModel from "../models/blogs";
import BlogCategoriasModel from "../models/blog-categorias";
import BlogModel from "../models/blog";
import InformacoesModel from "../models/informacoes";
import { Banners } from "../models/banners";
import { Menu, MenuRecursivo } from "../models/menu";
import CategoriasProdutoModel from "../models/categoriasProduto";
import ProdutosServicosModel from "../models/produtos-servicos";
import ProdutosServicosIdModel from "../models/produtos-servicos-id";
import QuemSomosModel from "../models/quem-somos";
import { ContatoStatus, ContatoType } from "../models/contato";
import BannersInternosModel from "../models/banners-interno";
import { GestorResponse } from "../models/gestor-response";
import DownloadFichaTecnicaModel from "../models/downloadFichaTecnica";
import {
  GenericContactType,
  GenerictContactStatus,
} from "../models/generic-contact";
import { SecaoTituloType } from "../models/secoes";

export const EMAIL_TO_SEND_GUIDE = "vendas@ultrapura.com";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/site`,
  headers: {
    company: 2,
    Accept: "application/json",
  },
});

const getBlogCategorias = async (): Promise<BlogCategoriasModel> => {
  const retorno = new BlogCategoriasModel();

  try {
    const { data, status } = (await api.get("/blog/categorias")) as any;

    return retorno.deserialize({ categorias: data });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Blog Categorias - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Blog Categorias: ", error);
    return retorno;
  }
};

const getBlogs = async (
  page?: number,
  limite?: number,
  categoria?: string
): Promise<BlogsModel> => {
  const retorno = new BlogsModel();

  try {
    const pesquisa =
      page != undefined && limite != undefined
        ? `?page=${page + 1}&limit=${limite}`
        : "";
    const filtro =
      pesquisa != "" && categoria != undefined ? `&category=${categoria}` : "";

    const { data, status } = (await api.get(
      `/blog${pesquisa + filtro}`
    )) as any;

    return retorno.deserialize(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Blog - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Blog : ", error);
    return retorno;
  }
};

const getBlog = async (id: string): Promise<BlogModel> => {
  const retorno = new BlogModel();

  try {
    const { data, status } = (await api.get(`/blog/${id}`)) as any;

    return retorno.deserialize({ dados: data });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Blog Id - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Blog Id: ", error);
    return retorno;
  }
};

const getInformacoes = async (): Promise<InformacoesModel> => {
  const retorno = new InformacoesModel();

  try {
    const { data, status } = (await api.get(`/informacoes`)) as any;

    return retorno.deserialize(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Informacoes - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Informacoes: ", error);
    return retorno;
  }
};

const getBanners = async (): Promise<Banners> => {
  const retorno = new Banners();

  try {
    const { data, status } = (await api.get("/banners")) as any;

    return retorno.deserialize({ dados: data });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on banners - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpected error on banners: ", error);
    return retorno;
  }
};

const getMenus = async (): Promise<GestorResponse<MenuRecursivo[]>> => {
  try {
    const { data } = await api.get<any>(`/menus`);

    var mapper = new Map<number, MenuRecursivo>();
    var array: MenuRecursivo[] = [];

    data.forEach((item: any) => {
      var menu = item as Menu;

      var arvoreMenus: MenuRecursivo = {
        menu: menu,
        submenus: [],
      };

      if (arvoreMenus.menu.id) {
        mapper.set(arvoreMenus.menu.id, arvoreMenus);
        if (menu.menu_id) {
          var father = mapper.get(menu.menu_id);
          if (father) {
            menu.html = (father.menu.html + menu.html!).replaceAll("#", ""); // menu.html = father.menu.html + menu.html! // Usar caso queira contruir o endpoint a partir das urls dos pais
            father.submenus.push(arvoreMenus);
          }
        } else {
          array.push(arvoreMenus);
        }
      }
    });

    return new GestorResponse(false, array);
  } catch (erro) {
    if (axios.isAxiosError(erro))
      console.warn("erro em getMenus: ", erro.message);
    else console.warn("erro inesperado em getMenus: ", erro);
    return new GestorResponse(true);
  }
};

const getCategoriasProduto = async (): Promise<CategoriasProdutoModel> => {
  const retorno = new CategoriasProdutoModel();

  try {
    const { data, status } = (await api.get(`/categorias`)) as any;
    return retorno.deserialize({ dados: data });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Categoria produto - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Categoria produto: ", error);
    return retorno;
  }
};

const getProdutosServicosPorCategoria = async (
  idCategoria: number
): Promise<ProdutosServicosModel> => {
  const retorno = new ProdutosServicosModel();

  try {
    const { data, status } = (await api.get(
      `/produtos_servicos/?page=1&limit=100&category=${idCategoria}`
    )) as any;

    return retorno.deserialize(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn(
        "Error on Produtos Servios por Categoria - message: ",
        error.message
      );
      return retorno;
    }

    console.warn("Unexpect error on Produtos Servios por Categoria: ", error);
    return retorno;
  }
};

const getProdutosServicos = async (): Promise<ProdutosServicosModel> => {
  const retorno = new ProdutosServicosModel();

  try {
    const { data, status } = (await api.get(`/produtos_servicos`)) as any;

    return retorno.deserialize(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Produtos Servios - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Produtos Servios: ", error);
    return retorno;
  }
};

const getProdutosServicosId = async (
  id: number | string
): Promise<ProdutosServicosIdModel> => {
  const retorno = new ProdutosServicosIdModel();

  try {
    const { data, status } = (await api.get(`/produtos_servicos/${id}`)) as any;

    return retorno.deserialize({ dados: data });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Produtos Servios Id - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Produtos Servios Id: ", error);
    return retorno;
  }
};

const getQuemSomos = async (): Promise<QuemSomosModel> => {
  const retorno = new QuemSomosModel();

  try {
    const { data, status } = (await api.get(`/quem_somos`)) as any;

    return retorno.deserialize(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Quem Somos - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Quem Somos: ", error);
    return retorno;
  }
};

const postContato = async (body: ContatoType): Promise<ContatoStatus> => {
  try {
    console.log("contato: ", body);
    const { data, status } = (await api.post(`/contato`, body)) as any;

    return ContatoStatus.OK;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Contato - message: ", error.message);
      return ContatoStatus.ERROR;
    }

    console.warn("Unexpect error on Contato: ", error);
    return ContatoStatus.ERROR;
  }
};

const postGenericContact = async (
  body: GenericContactType
): Promise<GenerictContactStatus> => {
  try {
    await api.post(`/send-email`, body);
    return GenerictContactStatus.OK;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Contato - message: ", error.message);
      return GenerictContactStatus.ERROR;
    }
    console.warn("Unexpect error on Contato: ", error);
    return GenerictContactStatus.ERROR;
  }
};

const getBannerInterno = async (
  pagina: string
): Promise<BannersInternosModel> => {
  const retorno = new BannersInternosModel();

  try {
    const { data, status } = (await api.get(
      `/bannerInterno?page=${pagina}`
    )) as any;

    return retorno.deserialize({ dados: data });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn("Error on Banner interno - message: ", error.message);
      return retorno;
    }

    console.warn("Unexpect error on Banner interno: ", error);
    return retorno;
  }
};

const getDownloadFichaTecnica =
  async (): Promise<DownloadFichaTecnicaModel> => {
    const retorno = new DownloadFichaTecnicaModel();

    try {
      const { data, status } = (await api.get(`/secao/index`)) as any;

      return retorno.deserialize({ dados: data });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.warn("Error on Banner interno - message: ", error.message);
        return retorno.deserialize({ erro: true });
      }

      console.warn("Unexpect error on Banner interno: ", error);
      return retorno.deserialize({ erro: true });
    }
  };

const getSecaoTitulo = async (
  titulo: string
): Promise<GestorResponse<SecaoTituloType>> => {
  try {
    const { data } = await api.get<any>(`/secao/titulo/${titulo}`);

    return new GestorResponse<SecaoTituloType>(false, data);
  } catch (erro) {
    if (axios.isAxiosError(erro))
      console.warn("erro em getSecaoTitulo: ", erro.message);
    else console.warn("erro inesperado em getSecaoTitulo: ", erro);
    return new GestorResponse(true);
  }
};

export const GestorRequisicoes = {
  getBlogCategorias,
  getBlogs,
  getBlog,
  getInformacoes,
  getBanners,
  getMenus,
  getCategoriasProduto,
  getProdutosServicos,
  getProdutosServicosPorCategoria,
  getProdutosServicosId,
  getQuemSomos,
  postContato,
  getBannerInterno,
  getDownloadFichaTecnica,
  postGenericContact,
  getSecaoTitulo,
};
