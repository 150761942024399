import HTMLReactParser from "html-react-parser";
import { relative } from "path";
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import logo from '../../images/ultrapura-logo-branca.png';
import InformacoesModel from "../../models/informacoes";
import { GestorRequisicoes } from "../../services/gestor";
import "./sessao-maps.scss"

class SessaoMaps extends Component {

  state: {
    maps?: string
  } = {};

  componentDidMount() {
    GestorRequisicoes.getInformacoes().then((data: InformacoesModel) => {
      this.state.maps = data.linkMaps;
      this.setState({ ...this.state });
    })
  }

  render(): React.ReactNode {
    return (
      <section id="Mapa">
        <div className="maps">
          {this.state.maps ? (HTMLReactParser(this.state.maps) as JSX.Element) :
          <SkeletonTheme>
            <Skeleton width="100%" height="550px"/>
          </SkeletonTheme>}
        </div>
      </section>
    );
  }
}

export default SessaoMaps;