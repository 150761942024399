import { IDeserializable } from "./deserealizable";

export default class DownloadFichaTecnicaModel implements IDeserializable {
  dados?: {
    data?: Array<{
      section_informations?: Array<
        {
          section_data?: {
            titulo?: string,
            imagensArray?: Array<{
              src?: string,
              firstShow?: string
            }>,
            arquivosArray?: Array<{
              src?: string
            }>
          },
          order?: number
        }>,
      subsections?: Array<any>
    }
    >,
    total?: number
  }

  error?: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}