import "./baixar-ficha-tecnica.scss"
import { Alert, Button, Form, Modal } from "react-bootstrap"
import { FormEvent, useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares"
import { GestorRequisicoes } from "../../services/gestor"
import DownloadFichaTecnicaModel from "../../models/downloadFichaTecnica"
import { ContatoStatus } from "../../models/contato"

export default function SessaoBaixarFichaTecnica() {

  const [dados, setDados] = useState<{
    controle: {
      modal: {
        status: boolean
      },
      form: {
        nome: string,
        email: string,
        cnpj: string,
        reCaptcha: string
      },
      formError: {
        nome: boolean,
        email: boolean,
        cnpj: boolean,
        reCaptcha: boolean
      },
      status: {
        ativo: boolean,
        variante: string,
        mensagem: string
      },
      download: {
        ativo: boolean
      }
    },
    gestorDados: {
      error: boolean,
      linkDownload?: string,
      textoBotao?: string,
      loading?:boolean
    }
  }>({
    controle: {
      modal: {
        status: false
      },
      form: {
        nome: "",
        email: "",
        cnpj: "",
        reCaptcha: ""
      },
      formError: {
        nome: false,
        email: false,
        cnpj: false,
        reCaptcha: false
      },
      status: {
        ativo: false,
        variante: "",
        mensagem: ""
      },
      download: {
        ativo: false
      }
    },
    gestorDados: {
      error: false,
      loading:true
    }
  });

  useEffect(() => {
    GestorRequisicoes.getDownloadFichaTecnica().then(resposta => {

      if (resposta.error || !resposta.dados?.data?.at(0)) {
        dados.gestorDados.error = true;
        dados.gestorDados.loading = false;
        setDados({ ...dados });
        return;
      }

      dados.gestorDados.linkDownload = resposta.dados?.data?.at(0)?.section_informations?.at(0)?.section_data?.arquivosArray?.at(0)?.src;
      dados.gestorDados.textoBotao = resposta.dados?.data?.at(0)?.section_informations?.at(0)?.section_data?.titulo;

      dados.gestorDados.loading = false;
      setDados({ ...dados });
    });
  }, []);

  const fechaModal = () => {
    dados.controle.modal.status = false;
    setDados({ ...dados });
  }

  const abreModal = () => {
    dados.controle.modal.status = true;
    setDados({ ...dados });
  }

  const enviar = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validaEmail = FuncoesAuxilizares.validaEmail;
    const validaCnpj = FuncoesAuxilizares.validaCnpj;

    //Verificando erros
    dados.controle.formError.nome = dados.controle.form.nome == "" || dados.controle.formError.nome;
    dados.controle.formError.email = !validaEmail(dados.controle.form.email) || dados.controle.formError.email;
    dados.controle.formError.cnpj = !validaCnpj(dados.controle.form.cnpj) || dados.controle.formError.cnpj;
    dados.controle.formError.reCaptcha = dados.controle.form.reCaptcha == "" || dados.controle.formError.reCaptcha;

    var erro = dados.controle.formError.nome || dados.controle.formError.email || dados.controle.formError.cnpj || dados.controle.formError.reCaptcha;

    if (erro) {
      setDados({ ...dados });
      return;
    }

    GestorRequisicoes.postContato({
      nome: dados.controle.form.nome,
      email: dados.controle.form.email,
      cnpj: dados.controle.form.cnpj,
      captcha: dados.controle.form.reCaptcha,
      mensagem: `${dados.controle.form.nome} fez download do documento técnico`
    }).then(resposta => {

      if (resposta == ContatoStatus.OK) {
        dados.controle.status = {
          ativo: true,
          mensagem: "Enviado com sucesso !",
          variante: "success"
        };
        dados.controle.download.ativo = true;
        dados.controle.form = {
          nome: "",
          cnpj: "",
          email: "",
          reCaptcha: ""
        }
        dados.controle.formError = {
          nome: false,
          cnpj: false,
          email: false,
          reCaptcha: false
        }
        setDados({ ...dados })
        return;
      }

      dados.controle.status = {
        ativo: true,
        mensagem: "Falha ao enviar !",
        variante: "danger"
      };
      dados.controle.download.ativo = false;
      setDados({ ...dados })
    });

  }

  const setNome = (event: React.ChangeEvent<any>) => {
    dados.controle.form.nome = event?.target?.value ?? "";
    dados.controle.formError.nome = false;
    setDados({ ...dados });
  }

  const setEmail = (event: React.ChangeEvent<any>) => {
    dados.controle.form.email = event?.target?.value ?? ""
    dados.controle.formError.email = false;
    setDados({ ...dados });
  }

  const setCnpj = (event: React.ChangeEvent<any>) => {
    dados.controle.form.cnpj = event?.target?.value ?? ""
    dados.controle.form.cnpj = FuncoesAuxilizares.mascararCnpj(dados.controle.form.cnpj);
    dados.controle.formError.cnpj = false;
    setDados({ ...dados });
  }

  const setCaptcha = (token?: string) => {
    dados.controle.form.reCaptcha = token ?? "";
    dados.controle.formError.reCaptcha = false;
    setDados({ ...dados });
  }

  if(dados.gestorDados.error || dados.gestorDados.loading)return null;

  return (
    <section id="SessaoBaixarFichaTecnica">

      <Modal id="ModalFichaTecnica" show={dados.controle.modal.status} onHide={fechaModal}>
        <Modal.Header closeButton>
          <Modal.Title>Insira os dados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={enviar}>

            <Form.Group className="mb-3" >
              <Form.Control className="formCampo" id="Nome" required type="text" placeholder="Seu nome"
                value={dados.controle.form.nome}
                isValid={dados.controle.form.nome ? true : false}
                isInvalid={dados.controle.formError.nome}
                onChange={setNome}
              />
              <Form.Control.Feedback type="invalid">
                Nome inválido
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control className="formCampo" id="Email" required type="email" placeholder="Seu email"
                value={dados.controle.form.email}
                onChange={setEmail}
                isValid={FuncoesAuxilizares.validaEmail(dados.controle.form.email)}
                isInvalid={dados.controle.form.email ?
                  !FuncoesAuxilizares.validaEmail(dados.controle.form.email) :
                  false || dados.controle.formError.email}
              />
              <Form.Control.Feedback type="invalid">
                Email inválido
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control className="formCampo" id="Cnpj" required type="text" placeholder="Seu CNPJ"
                value={dados.controle.form.cnpj}
                onChange={setCnpj}
                isValid={FuncoesAuxilizares.validaCnpj(dados.controle.form.cnpj)}
                isInvalid={dados.controle.form.cnpj ?
                  !FuncoesAuxilizares.validaCnpj(dados.controle.form.cnpj) :
                  false || dados.controle.formError.cnpj}
              />
              <Form.Control.Feedback type="invalid">
                CNPJ inválido
              </Form.Control.Feedback>
            </Form.Group>

            <div className={`RecaptchaContainer ${dados.controle.formError.reCaptcha ? "erro" : ''}`}>
              <ReCAPTCHA className="Recaptcha"
                sitekey="6LeGKx0hAAAAAMPu4yAcYsGk-rgly_dKlnNni8N6"
                onChange={(e: any) => { setCaptcha(e) }}
                onExpired={() => {
                  dados.controle.formError.reCaptcha = true
                  setDados({ ...dados });
                }}
              />
            </div>

            <Button className="botao" type="submit" variant="btn">
              Enviar
            </Button>

          </Form>
        </Modal.Body>
        <Modal.Footer>

          {dados.controle.status.ativo &&
            <Alert className="alert" variant={dados.controle.status.variante}>
              {dados.controle.status.mensagem}
            </Alert>}

          {dados.controle.download.ativo &&
            <a className="containerBotao" href={dados.gestorDados.linkDownload ?? ""} title={dados.gestorDados.linkDownload ?? ""}>
              <Button className="botao baixar" variant="btn">
                {dados.gestorDados.textoBotao ?? ""}
              </Button>
            </a>}

        </Modal.Footer>
      </Modal>

      <Button className="botaoFixo" variant="btn" onClick={abreModal}>
        BAIXAR FICHA TÉCNICA
      </Button>
    </section >
  )

}