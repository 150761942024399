import { Modal } from "react-bootstrap";
import "./styles.css"

export const SuccessSubscriptionModal = (props: any) => {
  const { data, ...safeProps} = props; 

  return data ? (
    <Modal
      size="lg"
      className="bs-none"
      {...safeProps}
      data={data}
    >
      <Modal.Header closeButton style={{ border: 'none' }} />
      <Modal.Body>
        <h5>{data.successSubscriptionTitle ?? 'Inscrição completa! Você receberá o guia de desinfecção em breve!'}</h5>
      </Modal.Body>
    </Modal>
  ) : <></>;
}