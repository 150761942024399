import { IDeserializable } from "./deserealizable";

export default class QuemSomosModel implements IDeserializable {
  id?: number;
  title?: string;
  text?: string;
  company?: number;
  createdAt?: string;
  updatedAt?: string;
  imagensArray?: Array<{
    src?: string,
    showFirst?: boolean
  }>

  deserialize(input: any): this {
    Object.assign(this, input)
    return this;
  }
}