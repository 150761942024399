import React, { Component } from "react";
import "./sessao-copyright.scss";
import iconeTytotech from "../../images/icons/tytotech.svg";
class sessaoCopyright extends Component {
  render(): React.ReactNode {
    return (
      <section id="sessaoCopyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 direitos">
              <p>
                © {new Date().getFullYear()} | Ultrapura. Todos os direitos
                reservados.
              </p>
            </div>
            <div className="col-lg-6 tytotech">
              <a
                href="https://tytotech.com.br/"
                title="Desenvolvido por Tytotech"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://tytotech.com.br/web-files/imagens/logos/logo-tytotech.svg"
                  alt="Tytotech"
                  width="85px"
                />
              </a>
              <span> {`${process.env.REACT_APP_VERSION}`}</span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default sessaoCopyright;
