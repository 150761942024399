import { Modal, Button } from "react-bootstrap";
import "./styles.css"

export const NewsletterModal = (props: any) => {
  const { dontShowAgain, subscribe, data, ...safeProps } = props;

  return data && data.image ? <Modal
    {...safeProps}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="modal-question bs-none"
  >
    <Modal.Header closeButton style={{ border: "none" }}>
    </Modal.Header>
    <Modal.Body>
      <img
        src={data?.image}
        style={{ width: "100%" }}
        alt=""
      />
    </Modal.Body>
    <Modal.Footer style={{ border: "none" }}>
      <Button variant="danger" onClick={() => {
        dontShowAgain();
      }}>Não mostrar novamente</Button>
      <Button onClick={() => {
        subscribe();
      }}>Inscrever-se</Button>
    </Modal.Footer>
  </Modal> : <></>
};