import { IDeserializable } from "./deserealize";

export class Banners implements IDeserializable {
  dados?: Array<
    {
      id?: number,
      image?: string,
      title?: string,
      description?: string
    }
  >

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}