import { IDeserializable } from "./deserealizable";

export type CategoriaProdutoType = {
  id?: number,
  title?: string,
  description?: string,
  image?: string
}

export default class CategoriasProdutoModel implements IDeserializable{
  dados?:Array<CategoriaProdutoType>
  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }
}