import { IDeserializable } from "./deserealizable";

export type ProdutoServicoType = {
  id?: number;
  title?: string;
  description?: string;
  specification?: string;
  category?: string;
  brand?: string;
  company?: number;
  createdAt?: string;
  updatedAt?: string;
  disabled?: boolean;
  characteristics_product_services?: Array<{
    characteristics_descriptions: {
      characteristics: {
        id: number;
        name: string;
      };
      description: string;
      id: number;
    };
  }>;
  products_services_files?: Array<{
    id?: number;
    file?: string;
    createdAt?: string;
    updatedAt?: string;
    company?: number;
    fileError?: boolean;
    showFirst?: boolean;
    product_service?: number;
  }>;
  imagensArray?: Array<{
    src?: string;
    showFirst?: boolean;
  }>;
  arquivosArray?: Array<{
    src?: string;
  }>;
};

export default class ProdutosServicosModel implements IDeserializable {
  data?: Array<ProdutoServicoType>;
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
