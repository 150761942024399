import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import "./sessao-blog.scss";
import { GestorRequisicoes } from "../../services/gestor";
import BlogCategorias, { CategoriaType } from "../../models/blog-categorias";
import BlogsModel, { BlogPostType } from "../../models/blogs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";

class SessaoBlog extends Component {
  state: {
    controle: {
      titulo: boolean;
      textoTitulo: string;
      selectCategorias: boolean;
      botaoSaibaMais: boolean;
      contadorDePaginas: boolean;
      cardPadrao: boolean;
      cardSlider: boolean;
    };
    cardsPost?: Array<BlogPostType>;
    listaDeCategorias: Array<CategoriaType>;
    categoriaAtual?: string;
    controlePaginas: {
      total: number;
      paginaAtual: number;
    };
  } = {
    controle: {
      titulo: false,
      textoTitulo: "",
      selectCategorias: false,
      botaoSaibaMais: false,
      contadorDePaginas: false,
      cardPadrao: false,
      cardSlider: false,
    },
    listaDeCategorias: [],
    controlePaginas: {
      total: 0,
      paginaAtual: 0,
    },
  };

  setCategoria(categoria: string) {
    window.scroll(0, 0);
    GestorRequisicoes.getBlogs(0, 9, categoria).then((data: BlogsModel) => {
      this.state.cardsPost = data.data as Array<BlogPostType>;
      this.state.controlePaginas = {
        paginaAtual: 0,
        total: Math.ceil((data?.total as number) / 9),
      };
      this.state.categoriaAtual = categoria;
      this.setState({ ...this.state });
    });
  }

  montaCategoriasSelect(categorias: Array<CategoriaType>) {
    const retorno: Array<JSX.Element> = [];

    categorias.forEach((categoria, index) => {
      retorno.push(
        <option key={index} value={categoria.id}>
          {categoria.name}
        </option>
      );
    });

    return retorno;
  }

  atualizaPagina = (pagina: number) => {
    window.scroll(0, 0);
    GestorRequisicoes.getBlogs(pagina, 9, this.state.categoriaAtual).then(
      (data: BlogsModel) => {
        this.state.controlePaginas.paginaAtual = pagina;
        this.state.cardsPost = data.data as Array<BlogPostType>;
        this.setState({ ...this.state });
      }
    );
  };

  montaControleDePaginas = () => {
    const retorno: Array<JSX.Element> = [];

    for (let i = 0; i < this.state.controlePaginas.total; i++) {
      retorno.push(
        <Button
          key={i}
          className={`botaoControlePagina ${
            i == this.state.controlePaginas.paginaAtual ? "ativo" : ""
          }`}
          variant="info"
          onClick={() => {
            this.atualizaPagina(i);
          }}
        >
          {i + 1}
        </Button>
      );
    }

    return retorno;
  };

  getCategoriaId(categoriaNome: string) {
    let idCategoria = 0;
    this.state.listaDeCategorias.forEach((categoria) => {
      if (categoria.name === categoriaNome) {
        idCategoria = categoria.id as number;
      }
    });
    return idCategoria;
  }

  montaCardPost(dados: Array<BlogPostType>) {
    const retorno: Array<JSX.Element> = [];

    dados.forEach((post, index) => {
      const texto = new DOMParser().parseFromString(
        post?.description as string,
        "text/html"
      )!.body!.firstChild!.firstChild!.textContent;
      retorno.push(
        <Col key={index} lg={4} className="colBlog">
          <Link
            to={`/blog/post/${post.id}/${this.getCategoriaId(
              post.category as string
            )}/${FuncoesAuxilizares.urlNormalize(post.title as string)}`}
            title={FuncoesAuxilizares.urlNormalize(post.title as string)}
            reloadDocument={true}
          >
            <Card>
              <Card.Img
                className="imagem"
                variant="top"
                src={post.imagensArray?.at(0)?.src}
              />
              <Card.Body>
                <Card.Title className="tituloPost">{post?.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {FuncoesAuxilizares.convertDataToDataAmigavel(
                    post?.start_date
                  )}
                </Card.Subtitle>
                <Card.Text className="textoPost">{texto}</Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      );
    });

    return retorno;
  }

  montaCardPostSlider(dados: Array<BlogPostType>) {
    const retorno: Array<JSX.Element> = [];

    dados.forEach((post, index) => {
      const texto = new DOMParser().parseFromString(
        post?.description as string,
        "text/html"
      )!.body!.firstChild!.firstChild!.textContent;
      retorno.push(
        <div key={index} className="colBlog">
          <Link
            to={`/blog/post/${post.id}/${this.getCategoriaId(
              post.category as string
            )}/${FuncoesAuxilizares.urlNormalize(post.title as string)}`}
            title={post.title}
            reloadDocument={true}
          >
            <Card>
              <Card.Img
                className="imagem"
                variant="top"
                src={post.imagensArray?.at(0)?.src}
              />
              <Card.Body>
                <Card.Title className="tituloPost">{post?.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {FuncoesAuxilizares.convertDataToDataAmigavel(
                    post?.start_date
                  )}
                </Card.Subtitle>
                <Card.Text className="textoPost">{texto}</Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      );
    });

    return retorno;
  }

  componentDidMount() {
    const listaDeCaminhosUrl = window.location.pathname.split("/");

    if (listaDeCaminhosUrl[1] === "") {
      this.state.controle = {
        titulo: true,
        textoTitulo: "Blog",
        selectCategorias: false,
        botaoSaibaMais: true,
        contadorDePaginas: false,
        cardPadrao: false,
        cardSlider: true,
      };
      GestorRequisicoes.getBlogCategorias().then((data: BlogCategorias) => {
        this.state.listaDeCategorias = data.categorias as Array<CategoriaType>;
        this.setState({ ...this.state });
      });
      GestorRequisicoes.getBlogs(0, 5).then((data: BlogsModel) => {
        this.state.cardsPost = data.data as Array<BlogPostType>;
        this.setState({ ...this.state });
      });
      return;
    }

    if (listaDeCaminhosUrl[2] === "post") {
      this.state.controle = {
        titulo: true,
        textoTitulo: "Veja mais",
        selectCategorias: false,
        botaoSaibaMais: false,
        contadorDePaginas: false,
        cardPadrao: false,
        cardSlider: true,
      };

      GestorRequisicoes.getBlogCategorias().then((data: BlogCategorias) => {
        this.state.listaDeCategorias = data.categorias as Array<CategoriaType>;
        this.setState({ ...this.state });
      });

      if (!!listaDeCaminhosUrl.at(4)) {
        GestorRequisicoes.getBlogs(
          0,
          5,
          listaDeCaminhosUrl.at(4) as string
        ).then((data: BlogsModel) => {
          this.state.cardsPost = data.data as Array<BlogPostType>;
          this.setState({ ...this.state });
        });
      }

      return;
    }

    if (listaDeCaminhosUrl[1] === "blog") {
      this.state.controle = {
        titulo: false,
        textoTitulo: "",
        selectCategorias: true,
        botaoSaibaMais: false,
        contadorDePaginas: true,
        cardPadrao: true,
        cardSlider: false,
      };
      GestorRequisicoes.getBlogCategorias().then((data: BlogCategorias) => {
        this.state.listaDeCategorias = data.categorias as Array<CategoriaType>;
        this.setState({ ...this.state });
      });
      GestorRequisicoes.getBlogs(0, 9).then((data: BlogsModel) => {
        this.state.cardsPost = data.data as Array<BlogPostType>;
        this.state.controlePaginas = {
          paginaAtual: 0,
          total: Math.ceil((data?.total as number) / 9),
        };
        this.setState({ ...this.state });
      });
      return;
    }
  }
  render(): React.ReactNode {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return this.state.cardsPost?.at(0) ? (
      <section id="sessaoBlog">
        <Container>
          {this.state.controle.titulo ? (
            <Row>
              <Col lg={12}>
                <h2 className="titulo">{this.state.controle.textoTitulo}</h2>
              </Col>
            </Row>
          ) : null}

          {this.state.controle.selectCategorias ? (
            <Row>
              <Col className="containerSelect" lg={4}>
                <select
                  className="selectCategorias"
                  name="categoria"
                  id="categoria"
                  defaultValue=""
                  onChange={(dado) => {
                    this.setCategoria(dado.target.value);
                  }}
                >
                  <option value="" disabled hidden>
                    Categorias
                  </option>
                  {this.montaCategoriasSelect(this.state.listaDeCategorias)}
                </select>
              </Col>
            </Row>
          ) : null}

          {this.state.controle.cardPadrao ? (
            <Row>{this.montaCardPost(this.state.cardsPost)}</Row>
          ) : null}

          {this.state.controle.cardSlider ? (
            <Row>
              <Col>
                <Carousel
                  responsive={responsive}
                  removeArrowOnDeviceType={["mobile", "tablet"]}
                  showDots={true}
                  dotListClass={"dotMenosDe" + this.state.cardsPost?.length}
                >
                  {this.montaCardPostSlider(this.state.cardsPost)}
                </Carousel>
              </Col>
            </Row>
          ) : null}

          {this.state.controle.botaoSaibaMais ? (
            <Row className="containerBotao">
              <Col lg={4}>
                <Link to="/blog">
                  <Button title="blog" className="botao" variant="info">
                    Saiba Mais
                  </Button>
                </Link>
              </Col>
            </Row>
          ) : null}

          {this.state.controle.contadorDePaginas ? (
            <Row>
              <Col className="containerControlePaginas" lg={12}>
                {this.montaControleDePaginas()}
              </Col>
            </Row>
          ) : null}
        </Container>
      </section>
    ) : this.state.controle.cardPadrao === undefined ? (
      <section id="sessaoBlog">
        <Container className="containerLoading">
          <SkeletonTheme
            highlightColor={COLORS.skeletonHighlightColor}
            baseColor={COLORS.skeletonBaseColor}
          >
            <Row>
              <Col lg={12}>
                <Skeleton width="100%" height="60px"></Skeleton>
              </Col>
            </Row>
            <Row>
              <Col className="cardLoading" lg={4}>
                <Skeleton width="100%" height="500px"></Skeleton>
              </Col>
              <Col className="cardLoading" lg={4}>
                <Skeleton width="100%" height="500px"></Skeleton>
              </Col>
              <Col className="cardLoading" lg={4}>
                <Skeleton width="100%" height="500px"></Skeleton>
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
      </section>
    ) : null;
  }
}

export default SessaoBlog;
