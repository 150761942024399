import React from "react";
import { Outlet } from "react-router-dom";
import SessaoBannerInterno from "../../components/sessao-banner-interno/sessao-banner-interno";
import SessaoBlog from "../../components/sessao-blog/sessao-blog";
import { useTitle } from "../../utilits/tittle";

import "./blog.scss"

export default function Blog(){
    window.scroll(0,0);
    useTitle("Blog")
    return(
        <section id="Blog">
            <SessaoBannerInterno path="blog"/>
            <Outlet/> 
        </section>
    );
} 