import React, { Component, useState } from "react";
import { Accordion, Col, Container, Row, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./sessao-produto-interno.scss"
import placeholder from '../../images/ULPcategoria.png';
import { GestorRequisicoes } from "../../services/gestor";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";
import { ProdutoServicoType } from "../../models/produtos-servicos";
import HTMLReactParser from "html-react-parser";
import ConteudoNaoEncontrado from "../../pages/pagina-nao-encontrada/conteudo-nao-encontrado";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { ContatoStatus, ContatoType } from "../../models/contato";
import ReCAPTCHA from "react-google-recaptcha";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import { ImagemViewerZoom } from "../imagem-viewer-zoom/imagem-viewer-zoom";

class SessaoProdutoInterno extends Component {

  state: {
    produto?: ProdutoServicoType,
    produtoNaoExiste?: boolean
    tenhoInteresse: ContatoType,
    tenhoInteresseError: {
      email?: boolean,
      nome?: boolean,
      cidade?: boolean,
      uf?: boolean,
      mensagem?: boolean,
      telefone?: boolean,
      captcha?: boolean,
    },
    imagensModal: string[],
    mensagemStatus: {
      mostrar?: boolean,
      texto?: string,
      tipo?: string
    }
  } = {
      tenhoInteresse: {},
      tenhoInteresseError: {},
      mensagemStatus: {},
      imagensModal: []
    };

  montaArquivosParaBaixar(arquivos?: Array<{ src?: string }>) {
    const retorno: Array<JSX.Element> = [];

    arquivos?.forEach((arquivo, index) => {
      retorno.push(
        <Col key={index} lg={12} className="arquivosProdutos">
          <a title={arquivo.src} target="#" href={arquivo.src}>
            <i className="bi bi-cloud-download" />
            <span>{arquivo.src?.split('/').pop()}</span>
          </a>
        </Col>
      );
    })

    return retorno;
  }

  mascararTelefone = (tel: string) => {
    let telMasked = tel.replace(/[^\d]/g, '')
    if (telMasked.length <= 9)
      telMasked = telMasked.replace(/(\d{2})(\d)/, "($1) $2");
    else
      telMasked = telMasked.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/, "($1) $2-$3");

    if (telMasked.length > 15) telMasked = telMasked.substring(0, 15);
    return telMasked;
  }

  mascararCnpj(cnpj: string) {
    let mascaraCnpj = cnpj.replace(/[^\d]/g, '')

    if (mascaraCnpj.length > 14) mascaraCnpj = mascaraCnpj.substring(0, 14);

    mascaraCnpj = mascaraCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4.$5")

    return mascaraCnpj;
  }

  setName = (nome: string) => {
    this.state.tenhoInteresse.nome = nome;
    this.state.tenhoInteresseError.nome = false;
    this.setState({ ...this.state });
  }

  setCNPJ = (CNPJ: string) => {
    this.state.tenhoInteresse.cnpj = this.mascararCnpj(CNPJ);
    this.state.tenhoInteresseError.mensagem = false;
    this.setState({ ...this.state });
  }

  setCidade = (cidade: string) => {
    this.state.tenhoInteresse.cidade = cidade;
    this.state.tenhoInteresseError.cidade = false;
    this.setState({ ...this.state })
  }

  setUf = (uf: string) => {
    this.state.tenhoInteresse.uf = uf;
    this.state.tenhoInteresseError.uf = false;
    this.setState({ ...this.state })
  }

  setTelefone = (telefone: string) => {
    this.state.tenhoInteresse.telefone = this.mascararTelefone(telefone);
    this.state.tenhoInteresseError.telefone = false;
    this.setState({ ...this.state })
  }

  setEmail = (email: string) => {
    this.state.tenhoInteresse.email = email;
    this.state.tenhoInteresseError.email = false;
    this.setState({ ...this.state })
  }

  setCaptcha = (token: string) => {
    this.state.mensagemStatus = {};

    this.state.tenhoInteresse.captcha = token;
    this.state.tenhoInteresseError.captcha = false;
    this.setState({ ...this.state })
  }

  validaTelefone(telefone?: string) {
    const regex = new RegExp('^\\(?[0-9]{2}\\)? ?9?[0-9]{4}-?[0-9]{4}$');

    if (regex.test(telefone as string)) return true;

    return false;
  }

  validadeEmail(email?: string) {
    const regex = new RegExp('^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$');

    if (regex.test(email?.toLowerCase() as string)) return true;

    return false;
  }

  validaCNPJ(cnpj: string) {
    return cnpj.length < 14 ? false : true;
  }

  enviaEmail = (event: any) => {
    event.preventDefault();

    this.state.tenhoInteresseError = {
      email: !this.state.tenhoInteresse.email,
      cidade: !this.state.tenhoInteresse.cidade,
      mensagem: !this.state.tenhoInteresse.cnpj,
      nome: !this.state.tenhoInteresse.nome,
      telefone: !this.state.tenhoInteresse.telefone,
      uf: !this.state.tenhoInteresse.uf,
      captcha: !this.state.tenhoInteresse.captcha
    };

    let todosCamposPrenchidos = true;

    for (const [key, value] of Object.entries(this.state.tenhoInteresseError)) {
      if (value) todosCamposPrenchidos = false;
    }

    if (!todosCamposPrenchidos) {
      if (this.state.tenhoInteresseError.captcha) {
        this.state.mensagemStatus = {
          mostrar: true,
          tipo: "warning",
          texto: "Por favor, responda ao reCAPTCHA !"
        }
      }
      this.setState({ ...this.state });
      return;
    }

    this.state.mensagemStatus = {
      mostrar: true,
      tipo: "info",
      texto: "Enviado as informacoes, aguarde... !"
    };

    this.setState({ ...this.state });

    this.state.tenhoInteresse.assunto = `Interesse no produto`
    this.state.tenhoInteresse.mensagem = this.state.produto?.title;

    GestorRequisicoes.postContato(this.state.tenhoInteresse).then((resposta) => {

      this.state.tenhoInteresse = {
        assunto: "",
        captcha: this.state.tenhoInteresse.captcha,
        cidade: "",
        cnpj: "",
        email: "",
        mensagem: "",
        nome: "",
        telefone: "",
        uf: ""
      }

      if (resposta === ContatoStatus.OK) {

        this.state.mensagemStatus = {
          mostrar: true,
          tipo: "success",
          texto: "Enviado com sucesso !"
        };

        this.setState({ ...this.state })
        return;
      }

      this.state.mensagemStatus = {
        mostrar: true,
        tipo: "danger",
        texto: "Falha ao enviar email !"
      };

      this.setState({ ...this.state })
      return;

    });

    return;
  }

  componentDidMount() {

    const listaDeCaminhosUrl = window.location.pathname.split('/');

    if (!!listaDeCaminhosUrl.at(3)) {
      GestorRequisicoes.getProdutosServicosId(listaDeCaminhosUrl.at(3) as string).then(dados => {
        this.state.produto = dados.dados;
        this.state.produtoNaoExiste = dados.dados ? false : true;
        this.setState({ ...this.state })
      });
      return;
    }
  }

  render(): React.ReactNode {

    const montaCarrosel = (imagens?: Array<{ showFirst?: boolean, src?: string }>) => {
      //Configuração para o carrosel
      const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const itens: Array<JSX.Element> = []

      const mostrarImagensNoModal = (first: number) => {
        const imagens = this.state.produto?.imagensArray?.map(img => img.src ?? '') ?? []
        var aux = imagens[0]
        imagens[0] = imagens[first]
        imagens[first] = aux
    
        this.setState({...this.state, imagensModal: imagens})
      }

      imagens?.forEach((iamgem, index) => {
        itens.push(
          <img key={index}
            className="d-block w-100"
            src={iamgem.src}
            onClick={() => mostrarImagensNoModal(index)}
          />)
      })

      return (
        <Carousel responsive={responsive} removeArrowOnDeviceType={["mobile", "tablet"]} showDots={itens?.length > 1 ? true : false}>
          {itens}
        </Carousel>
      );
    }

    return this.state.produto ? (
      <section id="sessaoProdutoInterno">
        <ImagemViewerZoom imagens={this.state.imagensModal ?? []} />
        <Container>
          <Row>
            <Col lg={6} className="colunaProduto">
              <Row>
                <Col lg={12} className="imgsCarrosel">
                  {montaCarrosel(this.state.produto.imagensArray)}
                </Col>
              </Row>

              {this.state.produto.specification ?
                <Row>
                  <Col md={12} className="tituloInfo">
                    <h4>Informações técnicas</h4>
                    <hr />
                  </Col>
                  <Col lg={12} className="infoProduto">
                    {HTMLReactParser(this.state.produto.specification.replace("noopener noreferrer", "") as string)}
                  </Col>
                </Row> : null}

              {this.state.produto.arquivosArray && !!this.state.produto?.arquivosArray.length ?
                <Row>
                  <Col lg={12} className="tituloArquivos">
                    <h4>Arquivos para baixar</h4>
                    <hr />
                  </Col>
                  {this.montaArquivosParaBaixar(this.state.produto.arquivosArray)}
                </Row> : null}

            </Col>
            <Col lg={6} className="colunaDetalhes">
              <Row>
                <Col lg={12} className="descProduto">
                  <h4>{this.state.produto.title}</h4>
                  {HTMLReactParser(this.state.produto.description as string)}
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="formInteresse">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Tem interesse neste produto? Entre em contato!</Accordion.Header>
                      <Accordion.Body className="formBody">

                        <Form noValidate onSubmit={this.enviaEmail}>

                          <Form.Group className="mb-3" controlId="nome">
                            <Form.Control type="text" placeholder="Nome"
                              value={this.state.tenhoInteresse.nome}
                              required
                              onChange={(e) => { this.setName(e.target.value) }}
                              isValid={this.state.tenhoInteresse.nome ? true : false}
                              isInvalid={this.state.tenhoInteresseError.nome} />
                            <Form.Control.Feedback type="invalid">
                              Nome inválido
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="cnpj">
                            <Form.Control type="text" placeholder="CNPJ"
                              value={this.state.tenhoInteresse.cnpj}
                              required
                              onChange={(e) => { this.setCNPJ(e.target.value) }}
                              isValid={this.validaCNPJ(this.state.tenhoInteresse.cnpj ?
                                this.state.tenhoInteresse.cnpj as string : "")}
                              isInvalid={this.state.tenhoInteresse.cnpj ?
                                !this.validaCNPJ(this.state.tenhoInteresse.cnpj) :
                                false || this.state.tenhoInteresseError.mensagem} />
                            <Form.Control.Feedback type="invalid">
                              CNPJ inválido
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="cidade">
                            <Form.Control type="text" placeholder="Cidade"
                              value={this.state.tenhoInteresse.cidade}
                              required
                              onChange={(e) => { this.setCidade(e.target.value) }}
                              isValid={this.state.tenhoInteresse.cidade ? true : false}
                              isInvalid={this.state.tenhoInteresseError.cidade} />
                            <Form.Control.Feedback type="invalid">
                              Cidade inválido
                            </Form.Control.Feedback>
                          </Form.Group>


                          <Form.Group className="mb-3" controlId="cidade">
                            <Form.Control type="text" placeholder="UF"
                              value={this.state.tenhoInteresse.uf}
                              required
                              onChange={(e) => { this.setUf(e.target.value) }}
                              isValid={this.state.tenhoInteresse.uf ? true : false}
                              isInvalid={this.state.tenhoInteresseError.uf} />
                            <Form.Control.Feedback type="invalid">
                              Uf inválido
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="telefone">
                            <Form.Control type="tel" placeholder="Telefone"
                              value={this.state.tenhoInteresse.telefone}
                              required
                              onChange={(e) => { this.setTelefone(e.target.value) }}
                              isValid={this.validaTelefone(this.state.tenhoInteresse.telefone)}
                              isInvalid={this.state.tenhoInteresse.telefone ?
                                !this.validaTelefone(this.state.tenhoInteresse.telefone) :
                                false || this.state.tenhoInteresseError.telefone} />
                            <Form.Control.Feedback type="invalid">
                              Telefone inválido
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="email">
                            <Form.Control type="email" placeholder="E-mail"
                              value={this.state.tenhoInteresse.email}
                              required
                              onChange={(e) => { this.setEmail(e.target.value) }}
                              isValid={this.validadeEmail(this.state.tenhoInteresse.email)}
                              isInvalid={this.state.tenhoInteresse.email ?
                                !this.validadeEmail(this.state.tenhoInteresse.email) :
                                false || this.state.tenhoInteresseError.email} />
                            <Form.Control.Feedback type="invalid">
                              E-mail inválido
                            </Form.Control.Feedback>

                            <ReCAPTCHA className="recaptcha"
                              sitekey="6LeGKx0hAAAAAMPu4yAcYsGk-rgly_dKlnNni8N6"
                              onChange={(e: any) => { this.setCaptcha(e) }}
                              onExpired={() => this.state.tenhoInteresse.captcha = undefined}
                            />

                            {this.state.mensagemStatus.mostrar ?
                              <Row>
                                <Col lg={12}>
                                  <Alert className="alert" variant={this.state.mensagemStatus.tipo}>
                                    {this.state.mensagemStatus.texto}
                                  </Alert>
                                </Col>
                              </Row> : null}

                          </Form.Group>

                          <Button title="enviar" variant="primary" type="submit">
                            Tenho interesse
                          </Button>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    ) : this.state.produtoNaoExiste ? <ConteudoNaoEncontrado /> : window.location.pathname.split('/').at(3) ? (
      <section id="sessaoProdutoInterno">
        <Container className="containerLoading">
          <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
            <Row>
              <Col lg={6}>
                <Skeleton className="imagemLoading" width="100%"></Skeleton>
              </Col>
              <Col lg={6}>
                <Skeleton className="descricaoLoading" width="100%" height="300px"></Skeleton>
                <Skeleton width="100%" height="50px"></Skeleton>
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
      </section>
    ) : null;
  }
}

export default SessaoProdutoInterno;