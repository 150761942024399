import { BlogPostType } from "./blogs";
import { IDeserializable } from "./deserealizable";

export default class BlogModel implements IDeserializable{
  dados?:BlogPostType;
  
  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }
}