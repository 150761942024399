import { Button } from "react-bootstrap";
import "./card-produto.scss";
import { Link } from "react-router-dom";
import { ChevronRight } from "react-bootstrap-icons";

export function CardProduto({
  link,
  img,
  titulo,
  verProduto,
  sombraMobile,
}: {
  link: string;
  img: string;
  titulo: string;
  verProduto: boolean;
  sombraMobile: boolean;
}) {
  return (
    <Link
      className={`produtoCard ${sombraMobile ? "" : "semSombraMobile"}`}
      title={titulo}
      to={link}
      reloadDocument
    >
      <div className="containerImagemProdutoCard">
        <img className="imagemProdutoCard" src={img} alt={titulo} />
      </div>
      <hr />
      <h3 className="tituloProdutoCard">{titulo}</h3>
      {verProduto && (
        <Button variant="btn" className="botao">
          <span>Ver Produto</span>
          <ChevronRight size="20" />
        </Button>
      )}
    </Link>
  );
}
