import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./sessao-banner-interno.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Banners } from "../../models/banners";
import { GestorRequisicoes } from "../../services/gestor";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import { Card } from "react-bootstrap";
import BannersInternosModel from "../../models/banners-interno";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";

const SessaoBannerInterno: React.FC<any> = (props) => {
  const [index, setIndex] = useState(0);

  const [banners, setBanners] = useState<BannersInternosModel>();
  
  useEffect(() => {
    GestorRequisicoes.getBannerInterno(FuncoesAuxilizares.urlNormalize(props.path)).then((resposta) => {
      setBanners(resposta);
    });
  }, []);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 400 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1
    }
  };
  return (
    <section id="bannerInterno">
      <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        <Carousel responsive={responsive} removeArrowOnDeviceType={["mobile", "tablet"]} showDots={true}>
          {banners?.dados?.at(0) ? banners?.dados?.at(0)?.imagensArray?.map((banner,index) => {
            return (
              <div key={index}>
                <img
                  className="d-block w-100 imgBannerInterna"
                  src={banner.src}
                  alt="bannerInterno"
                />
                <div className="captionCarousel">
                  <h3>{props.path}</h3>
                  <p>
                    {" "}
                    <Link className="botaoRota" to="/">
                      Home /{" "}
                    </Link>{" "}
                    {props.path}
                  </p>
                </div>
              </div>
            );
          }) : <Skeleton width="100%" height="250px" className="loadingBanner"/>}
        </Carousel>
      </SkeletonTheme>
    </section>
  );
};

export default SessaoBannerInterno;
