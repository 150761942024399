import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons'
import './imagem-viewer-zoom.scss'

export const ImagemViewerZoom = ({imagens}: {imagens: string[]}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    imagens.length && setShow(true)
  }, [imagens])

  return (
      <Modal className="sobreNosModal" show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Icon.XLg className="closeModal" onClick={() => setShow(false)}/>

        <Modal.Body>
                <Carousel controls={imagens.length > 1} indicators={imagens.length > 1}>
                  {
                    imagens.map((img, i) => (
                      <Carousel.Item key={i}>
                        <div className="img">
                          <img
                            className="d-block w-100"
                            src={img}
                            alt="First slide"
                          />
                        </div>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
        </Modal.Body>
      </Modal>
  )
}