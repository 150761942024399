import { type } from "os";
import { IDeserializable } from "./deserealizable";

export type BlogPostType = {
  id?: number,
  title?: string,
  description?: string,
  author?: string,
  company?: number,
  createdAt?: string,
  updatedAt?: string,
  category?: string,
  tags?: string,
  disabled?: boolean,
  end_date?: string,
  start_date?: string,
  imagensArray?: Array<
    {
      src?: string,
      showFirst?: boolean
    }
  >
}

export default class BlogsModel implements IDeserializable {
  data?: Array<BlogPostType>
  total?: number

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}