import { IDeserializable } from "./deserealizable";

export type CategoriaType = {
  id?: number,
  name?: string
}

export default class BlogCategoriasModel implements IDeserializable {
  categorias?: Array<CategoriaType>

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}