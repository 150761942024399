import HTMLReactParser from "html-react-parser";
import React, { Component } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ContatoStatus, ContatoType } from "../../models/contato";
import InformacoesModel from "../../models/informacoes";
import { GestorRequisicoes } from "../../services/gestor";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";
import ReCAPTCHA from "react-google-recaptcha";
import "./sessao-contato.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import 'react-loading-skeleton/dist/skeleton.css'

class sessaoContato extends Component {

  state: {
    numeroWhatsapp?: string,
    linkWhatsapp?: string,
    email?: string,
    endereco?: string,
    horarioDeAtendimento?: string,
    captcha_key?: string,
    contato: ContatoType,
    contatoError: {
      email?: boolean;
      nome?: boolean;
      assunto?: boolean;
      mensagem?: boolean;
      telefone?: boolean;
      captcha?: boolean;
    },
    mensagemStatus: {
      mostrar?: boolean,
      texto?: string,
      tipo?: string
    }
  } = {
      contato: {},
      contatoError: {},
      mensagemStatus: {}
    };

  setNome = (e: any) => {
    this.state.contato.nome = e?.target?.value;
    this.state.contatoError.nome = false;
    this.setState({ ...this.state })
  }

  setAssunto = (e: any) => {
    this.state.contato.assunto = e?.target?.value;
    this.state.contatoError.assunto = false;
    this.setState({ ...this.state })
  }

  setMensagem = (e: any) => {
    this.state.contato.mensagem = e?.target?.value;
    this.state.contatoError.mensagem = false;
    this.setState({ ...this.state })
  }

  setTelefone = (e: any) => {
    this.state.contato.telefone = this.mascararTelefone(e?.target?.value);
    this.state.contatoError.telefone = false;
    this.setState({ ...this.state })
  }

  setEmail = (e: any) => {
    this.state.contato.email = e?.target?.value;
    this.state.contatoError.email = false;
    this.setState({ ...this.state })
  }

  setCaptcha = (token?: string) => {

    this.state.mensagemStatus = {};

    this.state.contato.captcha = token;
    this.state.contatoError.captcha = false;
    this.setState({ ...this.state })
  }

  validaTelefone(numero?: string) {
    const regex = new RegExp('^\\(?[0-9]{2}\\)? ?9?[0-9]{4}-?[0-9]{4}$');

    if (regex.test(numero as string)) return true;

    return false;
  }

  validaEmail(email?: string) {

    const regex = new RegExp('^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$');

    if (regex.test(email?.toLowerCase() as string)) return true;

    return false;
  }

  enviarEmail = (event: any) => {

    event.preventDefault();

    this.state.contato.uf = "";
    this.state.contato.cidade = "";
    this.state.contato.cnpj = "";

    this.state.contatoError = {
      email: this.state.contato.email ? false : true,
      assunto: this.state.contato.assunto ? false : true,
      telefone: this.state.contato.telefone ? false : true,
      mensagem: this.state.contato.mensagem ? false : true,
      nome: this.state.contato.nome ? false : true,
      captcha: this.state.contato.captcha ? false : true
    }

    let validadoParaEnviar = true;

    for (const [key, value] of Object.entries(this.state.contatoError)) {

      if (value) validadoParaEnviar = false;

    }

    if (!validadoParaEnviar) {
      if (this.state.contatoError.captcha) {
        this.state.mensagemStatus = {
          mostrar: true,
          tipo: "warning",
          texto: "Por favor, responda ao reCAPTCHA !"
        }
      }

      this.setState({ ...this.state });
      return;
    }

    this.state.mensagemStatus = {
      mostrar: true,
      tipo: "info",
      texto: "Enviado as informacoes, aguarde... !"
    };

    this.setState({ ...this.state })

    GestorRequisicoes.postContato(this.state.contato).then((resposta) => {

      this.state.contato = {
        assunto: "",
        captcha: this.state.contato.captcha,
        cidade: "",
        cnpj: "",
        email: "",
        mensagem: "",
        nome: "",
        telefone: "",
        uf: ""
      };

      if (resposta === ContatoStatus.OK) {

        this.state.mensagemStatus = {
          mostrar: true,
          tipo: "success",
          texto: "Enviado com sucesso !"
        };

        this.setState({ ...this.state })
        return;
      }

      this.state.mensagemStatus = {
        mostrar: true,
        tipo: "danger",
        texto: "Falha ao enviar email !"
      };

      this.setState({ ...this.state })
      return;

    });
  }

  mascararTelefone = (tel: string) => {
    var telMasked = tel.replace(/[^\d]/g, '')
    if (telMasked.length <= 9)
      telMasked = telMasked.replace(/(\d{2})(\d)/, "($1) $2");
    else
      telMasked = telMasked.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/, "($1) $2-$3");

    if (telMasked.length > 15) telMasked = telMasked.substring(0, 15);
    return telMasked;
  }

  componentDidMount() {
    GestorRequisicoes.getInformacoes().then((dado: InformacoesModel) => {
      this.state = {
        numeroWhatsapp: dado.whatsapp,
        linkWhatsapp: FuncoesAuxilizares.apiWhatsapp(dado.whatsapp as string),
        email: dado.email,
        endereco: `${dado.address} ${dado.number}-${dado.city},${dado.state},${dado.cep}`,
        horarioDeAtendimento: dado.working_hours,
        captcha_key: dado.captcha_site_key,
        contato: this.state.contato,
        contatoError: this.state.contatoError,
        mensagemStatus: this.state.mensagemStatus
      }
      this.setState({ ...this.state })
    });
  }

  render(): React.ReactNode {
    return (
      <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        <section id="sessaoContato">
          <Container>
            <Row>
              <Col lg={12}>
                <h2 className="titulo">Fale Conosco</h2>
              </Col>

              <Col lg={8} className="colEsquerda">
                <Form noValidate onSubmit={this.enviarEmail}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" >
                        <Form.Control className="formCampo" id="Nome" required type="text" placeholder="Seu nome"
                          value={this.state.contato.nome}
                          isValid={this.state.contato.nome ? true : false}
                          isInvalid={this.state.contatoError.nome as boolean}
                          onChange={this.setNome} />
                        <Form.Control.Feedback type="invalid">
                          Nome inválido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control className="formCampo" id="Telefone" required type="tel" placeholder="Seu telefone"
                          value={this.state.contato.telefone}
                          onChange={this.setTelefone}
                          isValid={this.validaTelefone(this.state.contato.telefone)}
                          isInvalid={this.state.contato.telefone ?
                            !this.validaTelefone(this.state.contato.telefone) :
                            false || (this.state.contatoError.telefone as boolean)} />
                        <Form.Control.Feedback type="invalid">
                          Telefone inválido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control className="formCampo" id="Email" required type="email" placeholder="Seu email"
                          value={this.state.contato.email}
                          onChange={this.setEmail}
                          isValid={this.validaEmail(this.state.contato.email)}
                          isInvalid={this.state.contato.email ?
                            !this.validaEmail(this.state.contato.email) :
                            false || (this.state.contatoError.email as boolean)} />
                        <Form.Control.Feedback type="invalid">
                          Email inválido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control className="formCampo" id="Assunto" required type="text" placeholder="Assunto"
                          value={this.state.contato.assunto}
                          onChange={this.setAssunto}
                          isValid={this.state.contato.assunto ? true : false}
                          isInvalid={this.state.contatoError.assunto as boolean} />
                        <Form.Control.Feedback type="invalid">
                          Assunto inválido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <Form.Control
                      value={this.state.contato.mensagem}
                      required
                      id="Descricao"
                      type="text"
                      placeholder="Descrição"
                      as="textarea"
                      rows={5}
                      onChange={this.setMensagem}
                      isValid={this.state.contato.mensagem ? true : false}
                      isInvalid={this.state.contatoError.mensagem as boolean}
                    />
                    <Form.Control.Feedback type="invalid">
                      Descrição inválido
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row>
                    <Col lg={6}>
                      <ReCAPTCHA
                        sitekey="6LeGKx0hAAAAAMPu4yAcYsGk-rgly_dKlnNni8N6"
                        onChange={(e: any) => { this.setCaptcha(e) }}
                        onExpired={() => this.state.contato.captcha = undefined}
                      />
                    </Col>
                    <Col lg={6}>
                      <Button title="enviar" variant="primary" type="submit">
                        Fale Conosco
                      </Button>
                    </Col>
                  </Row>

                  {this.state.mensagemStatus.mostrar ?
                    <Row>
                      <Col lg={12}>
                        <Alert className="alert" variant={this.state.mensagemStatus.tipo}>
                          {this.state.mensagemStatus.texto}
                        </Alert>
                      </Col>
                    </Row> : null}
                </Form>

              </Col>
              <Col lg={4} className="colDireita">
                <div className="grupoContato">
                  <h3 className="tituloColDireita">Contato</h3>
                  <div>
                    <a href={this.state.linkWhatsapp ? this.state.linkWhatsapp as string : ""} target="#"
                      title={this.state.numeroWhatsapp} className="textoContato">
                      <i className="bi bi-whatsapp iconContato"></i>
                      {this.state.numeroWhatsapp ? <p>{this.state.numeroWhatsapp}</p> :
                        < Skeleton width="200px" height="20px" />}
                    </a>
                  </div>
                  <div>
                    <a href={this.state.email ? "mailto:" + this.state.email : ""} title={this.state.email} className="textoContato">
                      <i className="bi bi-envelope iconContato"></i>
                      {this.state.email ? <p>{this.state.email}</p> :
                        < Skeleton width="250px" height="20px" />}
                    </a>
                  </div>
                </div>

                <div className="grupoContato">
                  <h3 className="tituloColDireita">Endereço</h3>
                  <div>
                    <div className="textoContato">
                      <i className="bi bi-geo-alt iconContato"></i>
                      {this.state.endereco ? <p>{this.state.endereco}</p> :
                        < Skeleton width="250px" height="20px" count={2} />}
                    </div>
                  </div>
                </div>
                <div className="grupoContato">
                  <h3 className="tituloColDireita">Horário de atendimento</h3>
                  <div className="textoContato">
                    <i className="bi bi-calendar iconContato"></i>
                    {this.state.horarioDeAtendimento ?
                      HTMLReactParser(this.state.horarioDeAtendimento as string) :
                      <Skeleton width="200px" height="20px" count={3} />}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section >
      </SkeletonTheme>
    );
  }
}

export default sessaoContato;