import React, { Component } from "react";
import {
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import "./sessao-header.scss";
import { GestorRequisicoes } from "../../services/gestor";
import InformacoesModel from "../../models/informacoes";
import { MenuRecursivo } from "../../models/menu";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import { Telephone } from "react-bootstrap-icons";

class SessaoHeader extends Component {
  state: {
    logoImg?: string;
    numeroContato?: string;
    menus?: MenuRecursivo[];
  } = {};

  monstaMenu(menus?: Array<MenuRecursivo>) {
    const retorno: Array<JSX.Element> = [];

    const montarSubmenus = (
      baseHref: string,
      rotas: Array<MenuRecursivo>,
      nivel: number
    ) => {
      const retorno: Array<JSX.Element> = [];
      rotas.forEach((rota, index) => {
        retorno.push(
          rota.submenus.length ? (
            <NavDropdown
              key={index + baseHref}
              title={rota.menu?.name}
              className={`submenu submenu-${nivel}`}
            >
              {montarSubmenus(
                rota.menu?.html as string,
                rota.submenus,
                nivel + 1
              )}
            </NavDropdown>
          ) : (
            <NavDropdown.Item key={index} href={rota.menu?.html ?? ""}>
              <div className="selecao" />
              {rota.menu?.name}
            </NavDropdown.Item>
          )
        );
      });

      return retorno;
    };

    menus?.forEach((rotaRaiz, index) => {
      const rotasFilho: Array<MenuRecursivo> = [];

      rotaRaiz.submenus.forEach((rotaSub) => {
        rotasFilho.push(rotaSub);
      });

      retorno.push(
        rotaRaiz.submenus?.length ? (
          <NavDropdown
            key={index}
            title={rotaRaiz.menu?.name}
            className="hasSubmenus"
          >
            {montarSubmenus(
              rotaRaiz.menu?.html as string,
              rotaRaiz.submenus,
              0
            )}
          </NavDropdown>
        ) : (
          <Nav.Link
            title={rotaRaiz.menu?.name}
            key={index}
            href={rotaRaiz.menu?.html}
          >
            {rotaRaiz.menu?.name}
          </Nav.Link>
        )
      );
    });

    return retorno;
  }

  componentDidMount() {
    GestorRequisicoes.getInformacoes().then((dado: InformacoesModel) => {
      this.state.logoImg = dado.image;
      this.state.numeroContato = dado.phones?.at(0)?.phone;
      this.setState({ ...this.state });
    });
    GestorRequisicoes.getMenus().then((dado) => {
      this.state.menus = dado.dados;
      this.setState({ ...this.state });
    });
  }
  render(): React.ReactNode {
    return (
      <SkeletonTheme
        baseColor={COLORS.skeletonBaseColor}
        highlightColor={COLORS.skeletonHighlightColor}
      >
        <section id="sessaoHeader">
          <Navbar className="menu" expand="lg">
            <Container>
              <Navbar.Brand href="/" title="Home">
                {this.state.logoImg ? (
                  <img
                    src={this.state.logoImg}
                    alt="Ultrapura"
                    className="logotipo"
                  />
                ) : (
                  <Skeleton className="loadingLogo" />
                )}
              </Navbar.Brand>
              <Navbar.Toggle />
              {this.state.menus ? (
                <Navbar.Offcanvas id="header" placement="end">
                  <Offcanvas.Header closeButton />
                  <Offcanvas.Body className="headerBody">
                    <div className="containerLogoMobile">
                      <img
                        src={this.state.logoImg}
                        alt="Ultrapura"
                        className="logotipoMobile"
                      />
                    </div>
                    {this.monstaMenu(this.state.menus)}
                    <Nav.Item>
                      <a
                        className="nav-link ancora"
                        href={`tel:+${this.state.numeroContato}`}
                        title={this.state.numeroContato}
                      >
                        <Telephone className="iconeTelefone" size={20} />
                        <span className="telefone">
                          {this.state.numeroContato}
                        </span>
                      </a>
                    </Nav.Item>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              ) : (
                <Skeleton className="loadingMenu" width="700px" height="50px" />
              )}
            </Container>
          </Navbar>
        </section>
      </SkeletonTheme>
    );
  }
}

export default SessaoHeader;
