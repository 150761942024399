import { IDeserializable } from "./deserealizable";

export default class BannersInternosModel implements IDeserializable {
  dados?: Array<{
    imagensArray?: Array<{
      src?: string,
      showFirst?: boolean
    }>
  }>
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}