import HTMLReactParser from "html-react-parser";
import { relative } from "path";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/ultrapura-logo-branca.png";
import InformacoesModel, { SocialMediaType } from "../../models/informacoes";
import { GestorRequisicoes } from "../../services/gestor";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";
import "./sessao-footer.scss";

class SessaoFooter extends Component {
  state: {
    numeroWhatsapp?: string;
    logo?:string;
    linkWhatsapp?: string;
    numeroContato?: string;
    email?: string;
    endereco?: string;
    horarioDeAtendimento?: string;
    redesSociais?: Array<SocialMediaType>;
  } = {};

  componentDidMount() {
    GestorRequisicoes.getInformacoes().then((dado: InformacoesModel) => {
      this.state = {
        numeroWhatsapp: dado.whatsapp,
        logo: dado.image,
        numeroContato: dado.phones?.at(0)?.phone,
        linkWhatsapp: FuncoesAuxilizares.apiWhatsapp(dado.whatsapp as string),
        email: dado.email,
        endereco: `${dado.address} ${dado.number} - ${dado.city}, ${dado.state}, ${dado.cep}`,
        horarioDeAtendimento: dado.working_hours,
        redesSociais: dado.social_media,
      };
      this.setState({ ...this.state });
    });
  }

  mostaRedesSociais(redesSociais?: Array<SocialMediaType>) {
    const retorno: Array<JSX.Element> = [];

    redesSociais?.forEach((redeSocial, index) => {
      retorno.push(
        <a
          title={redeSocial.link ? (redeSocial.link as string) : ""}
          key={index}
          href={redeSocial.link ? (redeSocial.link as string) : ""}
          className="circulo"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className={
              "bi bi-" +
              redeSocial.icon?.toLocaleLowerCase().replace("icon", "")
            }
          />
        </a>
      );
    });

    return retorno;
  }

  render(): React.ReactNode {
    return (
      <section id="Footer">
        <a
          title={this.state.numeroWhatsapp}
          href={this.state.linkWhatsapp ? this.state.linkWhatsapp : ""}
          className="links"
          id="btn-whatsapp"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-whatsapp" />
        </a>
        <div className="container copyrightco">
        <hr className="barra"/>
          <div className="row">
            <div className="col-lg-3">
              <img src={this.state.logo} width="100%" alt="Logo" />
              <div className="iconesRodape">
                {this.mostaRedesSociais(this.state.redesSociais)}
              </div>
            </div>
            <div className="col-lg-3 itemRodape">
              <h3 className="tituloItem">Contato</h3>
              <div>
                <a
                  title={this.state.numeroWhatsapp}
                  href={this.state.linkWhatsapp ? this.state.linkWhatsapp : ""}
                  className="links"
                >
                  {this.state.numeroWhatsapp}
                </a>
                <br />
                <a
                  className="links"
                  href={`tel:+${this.state.numeroContato}`}
                  title={this.state.numeroContato}
                >
                  {this.state.numeroContato}
                </a>
                <br />
                <a
                  title={this.state.email}
                  href={this.state.email ? "mailto:" + this.state.email : ""}
                  className="links"
                >
                  {this.state.email}
                </a>
              </div>
            </div>
            <div className="col-lg-3 itemRodape">
              <h3 className="tituloItem">Endereço</h3>
              <div>
                <div className="links">{this.state.endereco}</div>
              </div>
            </div>
            <div className="col-lg-3 itemRodape">
              <h3 className="tituloItem">Horário de atendimento</h3>
              <div>
                {HTMLReactParser(
                  this.state.horarioDeAtendimento
                    ? (this.state.horarioDeAtendimento as string)
                    : ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SessaoFooter;
