import { useEffect, useState } from "react";
import { Alert, Button, Container, Form, Modal, Row, Col } from "react-bootstrap";
import { FuncoesAuxilizares } from "../../../../utilits/funcoes-auxiliares"
import { EMAIL_TO_SEND_GUIDE, GestorRequisicoes } from "../../../../services/gestor";
import { GenericContactType, GenerictContactStatus } from "../../../../models/generic-contact";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles.css";


enum PostStatus {
  ENVIADO = 'ENVIADO',
  ENVIANDO = 'ENVIANDO',
  ERRO = 'ERRO',
  NULL = 'NULL'
}

enum ClienteFinal {
  REVENDA = "REVENDA",
  INDUSTRIA = "INDUSTRIA",
  HOSPITAIS_CLINICAS = "HOSPITAIS/CLINICAS",
  CLIENT_FINAL = "CLIENTE FINAL"
}

type SubscribeData = {
  name: string,
  email: string,
  cnpj: string,
  phone: string,
  finalClient: ClienteFinal | null,
  brand: string,
  model: string,
}

type SubscribeState = {
  name?: boolean,
  email?: boolean,
  cnpj?: boolean,
  phone?: boolean,
  finalClient?: boolean,
  brand?: boolean,
  model?: boolean
}

const SUBSCRIBE_STATE_INITIAL = {}

const SUBSCRIBE_DATA_INITIAL = {
  name: "",
  email: "",
  cnpj: "",
  phone: "",
  finalClient: null,
  brand: "",
  model: ""
}

const FormControlText = (props: any): JSX.Element => (
  <Form.Control
    type="text"
    spellCheck={false}
    autoComplete="off"
    autoCorrect="off"
    style={{ boxShadow: "none" }}
    {...props}
  />
)

const validateLength = (input: string) => (input.length > 3)

export const NewsletterSubscribeModal = (props: any) => {

  const { data } = props

  const [values, setValues] = useState<SubscribeData>(SUBSCRIBE_DATA_INITIAL);
  const [state, setState] = useState<SubscribeState>(SUBSCRIBE_STATE_INITIAL);
  const [status, setStatus] = useState<PostStatus>(PostStatus.NULL)

  const [captcha, setCaptcha] = useState<any>(undefined);
  const [captchaSiteKey, setCaptchaSiteKey] = useState<string>();

  useEffect(() => {
    GestorRequisicoes.getInformacoes().then(response => {
      setCaptchaSiteKey(response.captcha_site_key)
    })
  }, [])

  const setCNPJ = (inputCNPJ: string) => {
    const cnpj = FuncoesAuxilizares.mascararCPForCNPJ(inputCNPJ)
    const isValid = FuncoesAuxilizares.validateCPForCNPJ(cnpj);
    if (isValid) setState({ ...state, cnpj: true });
    else setState({ ...state, cnpj: undefined })
    setValues({ ...values, cnpj })
  }

  const setEmail = (inputEmail: string) => {
    const isValid = FuncoesAuxilizares.validaEmail(inputEmail);
    if (isValid) setState({ ...state, email: true });
    else setState({ ...state, email: undefined });
    setValues({ ...values, email: inputEmail })
  }

  const setName = (inputName: string) => {
    const isValid = validateLength(inputName)
    if (isValid) setState({ ...state, name: true });
    else setState({ ...state, name: undefined });
    setValues({ ...values, name: inputName })
  }

  const setPhone = (inputPhone: string) => {
    const phone = FuncoesAuxilizares.mascararTelefone(inputPhone);
    const isValid = FuncoesAuxilizares.validaTelefone(phone);
    if (isValid) setState({ ...state, phone: true });
    else setState({ ...state, phone: undefined });
    setValues({ ...values, phone })
  }

  const setBrand = (inputBrand: string) => {
    const isValid = validateLength(inputBrand);
    if (isValid) setState({ ...state, phone: true });
    else setState({ ...state, brand: undefined });
    setValues({ ...values, brand: inputBrand })
  }


  const setModel = (inputModel: string) => {
    const isValid = validateLength(inputModel);
    if (isValid) setState({ ...state, model: true });
    else setState({ ...state, model: undefined });
    setValues({ ...values, model: inputModel })
  }


  const subscribe = async () => {
    setStatus(PostStatus.ENVIANDO)

    const bodyPost: GenericContactType = {
      to: EMAIL_TO_SEND_GUIDE,
      captcha: '237441fa2dac27030186e90de4a5b05a', //captcha ?? '',
      subject: 'Site Ultrapura: Receber guia de desinfecção e limpeza química de membranas',
      emailBody: {
        "Nome": values.name,
        "Email": values.email,
        "CNPJ/CPF": values.cnpj,
        "Marca": values.brand,
        "Modelo": values.model,
        "Telefone": values.phone,
        "Tipo cliente": values.finalClient,
        "message": `Email enviado por Gestor Tytotech - ${data.subscriptionTitle ?? 'Formulário: Receba grátis o guia de desinfecção e limpeza química de membranas!'}`
      }
    }

    console.log(bodyPost)

    const response = await GestorRequisicoes.postGenericContact(bodyPost)

    if (response === GenerictContactStatus.OK) {
      props.registered();
    } else {
      setStatus(PostStatus.ERRO)
      setTimeout(() => setStatus(PostStatus.NULL), 2000)
    }
  }

  const handleSubmit = () => {
    const stateErrors: SubscribeState = {
      name: validateLength(values.name),
      email: FuncoesAuxilizares.validaEmail(values.email),
      cnpj: FuncoesAuxilizares.validateCPForCNPJ(values.cnpj),
      phone: FuncoesAuxilizares.validaTelefone(values.phone),
      finalClient: values.finalClient !== null,
      brand: validateLength(values.brand),
      model: validateLength(values.model)
    }

    let validadoParaEnviar = true;

    for (const [key, value] of Object.entries(stateErrors)) {
      if (!value) {
        validadoParaEnviar = false;
        break;
      }
    }

    if (!validadoParaEnviar) {
      setState({ ...stateErrors });
    } else subscribe()
  }

  return data ? (
    <Modal show={props.show} onHide={props.handleClose} size="lg" className="bs-none">
      <Modal.Header closeButton style={{ border: "none" }} className="mb-3">
        <Modal.Title>{data.subscriptionTitle ? data.subscriptionTitle : 'Formulário: Receba grátis o guia de desinfecção e limpeza química de membranas'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Container fluid>
            <Row>
              <Col xs={12} lg={6} className="mb-3 pl0">
                <Form.Group>
                  <Form.Label>Qual seu nome ?</Form.Label>
                  <FormControlText
                    readOnly={status === PostStatus.ENVIADO || status === PostStatus.ENVIANDO}
                    // placeholder="Nome e Sobrenome"
                    value={values.name}
                    onChange={(e: any) => setName(e?.target?.value ?? "")}
                    onBlur={(e: any) => setState({ ...state, name: validateLength(values.name) })}
                    isInvalid={state.name === false}
                    isValid={state.name === true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className="mb-3 pr0">
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Telefone</Form.Label>
                  <FormControlText
                    readOnly={status === PostStatus.ENVIADO || status === PostStatus.ENVIANDO}
                    // placeholder="(99) 9 9999-9999"
                    value={values.phone}
                    onChange={(e: any) => setPhone(e?.target?.value ?? "")}
                    onBlur={(e: any) => setState({ ...state, phone: FuncoesAuxilizares.validaTelefone(e?.target?.value ?? "") })}
                    isInvalid={state.phone === false}
                    isValid={state.phone === true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className="mb-3 pl0">
                <Form.Group>
                  <Form.Label>CNPJ ou CPF</Form.Label>
                  <FormControlText
                    readOnly={status === PostStatus.ENVIADO || status === PostStatus.ENVIANDO}
                    // placeholder="99.409.731/0001-52"
                    value={values.cnpj}
                    onChange={(e: any) => setCNPJ(e?.target?.value ?? "")}
                    onBlur={(e: any) => setState({ ...state, cnpj: FuncoesAuxilizares.validateCPForCNPJ(e?.target?.value ?? "") })}
                    isInvalid={state.cnpj === false}
                    isValid={state.cnpj === true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className="mb-3 pr0">
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Email</Form.Label>
                  <FormControlText
                    readOnly={status === PostStatus.ENVIADO || status === PostStatus.ENVIANDO}
                    // placeholder="nome@exemplo.com"
                    value={values.email}
                    onChange={(e: any) => setEmail(e?.target?.value ?? "")}
                    onBlur={(e: any) => setState({ ...state, email: FuncoesAuxilizares.validaEmail(e?.target?.value ?? "") })}
                    isInvalid={state.email === false}
                    isValid={state.email === true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className="mb-3 pl0">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Label>Qual marca de membrana você utiliza ?</Form.Label>
                  <FormControlText
                    readOnly={status === PostStatus.ENVIADO || status === PostStatus.ENVIANDO}
                    // placeholder="Sua resposta aqui"
                    value={values.brand}
                    onChange={(e: any) => setBrand(e?.target?.value ?? "")}
                    onBlur={(e: any) => setState({ ...state, brand: validateLength(e?.target?.value ?? "") })}
                    isInvalid={state.brand === false}
                    isValid={state.brand === true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className="mb-3 pr0">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Label>Qual modelo de membrana você utiliza ?</Form.Label>
                  <FormControlText
                    readOnly={status === PostStatus.ENVIADO || status === PostStatus.ENVIANDO}
                    // placeholder="Sua resposta aqui"
                    value={values.model}
                    onChange={(e: any) => setModel(e?.target?.value ?? "")}
                    onBlur={(e: any) => setState({ ...state, model: validateLength(e?.target?.value ?? "") })}
                    isInvalid={state.model === false}
                    isValid={state.model === true}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="client-type d-flex flex-row justify-content-between">
                  <Form.Label>Que tipo de cliente você é ?</Form.Label>
                  <Form.Check
                    type="radio"
                    aria-label="radio 2"
                    label="Revenda"
                    onChange={() => {
                      setValues({ ...values, finalClient: ClienteFinal.REVENDA });
                      setState({ ...state, finalClient: true })
                    }}
                    checked={values.finalClient === ClienteFinal.REVENDA}
                    isInvalid={state.finalClient === false}
                  />
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    label="Indústria"
                    onChange={() => {
                      setValues({ ...values, finalClient: ClienteFinal.INDUSTRIA });
                      setState({ ...state, finalClient: true });
                    }}
                    checked={values.finalClient === ClienteFinal.INDUSTRIA}
                    isInvalid={state.finalClient === false}
                  />
                  <Form.Check
                    type="radio"
                    aria-label="radio 2"
                    label="Hospitais/Clínicas"
                    onChange={() => {
                      setValues({ ...values, finalClient: ClienteFinal.HOSPITAIS_CLINICAS });
                      setState({ ...state, finalClient: true });
                    }}
                    checked={values.finalClient === ClienteFinal.HOSPITAIS_CLINICAS}
                    isInvalid={state.finalClient === false}
                  />
                  <Form.Check
                    type="radio"
                    aria-label="radio 2"
                    label="Cliente Final"
                    onChange={() => {
                      setValues({ ...values, finalClient: ClienteFinal.CLIENT_FINAL });
                      setState({ ...state, finalClient: true });
                    }}
                    checked={values.finalClient === ClienteFinal.CLIENT_FINAL}
                    isInvalid={state.finalClient === false}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ border: "none", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
        {captchaSiteKey && (
          <ReCAPTCHA
            sitekey={captchaSiteKey}
            onChange={(value) => setCaptcha(value)}
            onErrored={() => setCaptcha(undefined)}
            onExpired={() => setCaptcha(undefined)}
          />
        )}
        {status === PostStatus.NULL &&
          <Button variant="primary" onClick={handleSubmit} disabled={!captcha}>
            Enviar
          </Button>
        }
        {status !== PostStatus.NULL &&
          <Alert
            variant={status === PostStatus.ENVIADO ? 'success' : status === PostStatus.ENVIANDO ? 'primary' : 'danger'}
            style={{ padding: "6px 12px", maxWidth: "70%" }}
          >
            {status === PostStatus.ENVIANDO ? 'Realizando inscrição !' : 'Erro ao realizar inscrição, tente novamente mais tarde !'}
          </Alert>
        }
      </Modal.Footer>
    </Modal>
  ) : <></>;
};