import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BlogModel from "../../models/blog";
import { BlogPostType } from "../../models/blogs";
import { GestorRequisicoes } from "../../services/gestor";
import "./sessao-post.scss"
import ReactHtmlParser from 'html-react-parser';
import calendario from "../../images/icons/calendario.svg"
import pasta from "../../images/icons/pasta.svg"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import { ImagemViewerZoom } from "../imagem-viewer-zoom/imagem-viewer-zoom";

class SessaoPost extends Component {
  state: {
    imagensModal: string[],
    showModal: boolean,
    blog: BlogPostType
  } = {
    imagensModal: [],
    showModal: false,
    blog: {}
  };

  // montaImagensCarousel(setState:(obj: any) => void, state: any, listaDeImagens?: Array<{ src?: string, showFirst?: boolean }>): Array<JSX.Element> {
  //   const retorno: Array<JSX.Element> = [];

  //   const mostrarImagensNoModal = (first: number) => {
  //     console.log('mostrar imagem no modal')
  //     const imagens = listaDeImagens?.map(img => img.src ?? '') ?? []
  //     var aux = imagens[0]
  //     imagens[0] = imagens[first]
  //     imagens[first] = aux
  
  //     this.setState({this.state, imagensModal: imagens})
  //   }

  //   listaDeImagens?.forEach((imagem, index) => {
  //     retorno.push(
  //       <div key={index} className="containerImagem" onClick={() => mostrarImagensNoModal(index)}>
  //         <img
  //           className="imagemCarousel"
  //           src={imagem.src}
  //           alt="imagem carousel"
  //         />
  //       </div>
  //     );
  //   })

  //   return retorno;
  // }

  componentDidMount() {
    const listaDeCaminhosUrl = window.location.pathname.split('/');

    if (!!listaDeCaminhosUrl.at(3)) {
      GestorRequisicoes.getBlog(listaDeCaminhosUrl.at(3) as string).then((data: BlogModel) => {
        this.state.blog = data.dados as BlogPostType;
        this.setState({ ...this.state })
      })
    }
  }

  mostrarImagensNoModal(first: number) {
    const imagens = this.state.blog.imagensArray?.map(img => img.src ?? '') ?? []
    var aux = imagens[0]
    imagens[0] = imagens[first]
    imagens[first] = aux

    this.setState({...this.state, imagensModal: imagens})
  }

  render(): React.ReactNode {
    return this.state.blog.id ? (
      <section id="SessaoPost">
        <ImagemViewerZoom imagens={this.state.imagensModal ?? []} />
        <Container>
          <Row>
            <Col className="containerInfoPost superior" lg={4}>
              <div className="infoPost">
                <img className="infoImagem" src={calendario} alt="data" />
                <p className="infoTexto" >{FuncoesAuxilizares.convertDataToDataAmigavel(this.state?.blog.start_date)}</p>
              </div>
              <div className="infoPost">
                <img className="infoImagem" src={pasta} alt="categoria" />
                <p className="infoTexto">{this.state.blog.category}</p>
              </div>
            </Col>
            <Col>
              <h2 className="titulo" >{this.state?.blog.title}</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <Carousel responsive={{
                desktop: {
                  breakpoint: { max: 4000, min: 0 },
                  items: 1
                }
              }} showDots={this.state.blog.imagensArray?.length as number > 1} arrows={true}>
                { this.state.blog.imagensArray?.map((imagem, index) => (
                    <div key={index} className="containerImagem" onClick={() => this.mostrarImagensNoModal(index)}>
                      <img
                        className="imagemCarousel"
                        src={imagem.src}
                        alt="imagem carousel"
                      />
                    </div>
                  )
                ) }
              </Carousel>
              <div className="containerTexto">
                {this.state?.blog.description != undefined ? ReactHtmlParser(this.state?.blog.description as string) : null}
              </div>
            </Col>
            <Col className="containerInfoPost lateral" lg={4}>
              <div className="infoPost">
                <img className="infoImagem" src={calendario} alt="data" />
                <p className="infoTexto" >{FuncoesAuxilizares.convertDataToDataAmigavel(this.state?.blog.start_date)}</p>
              </div>
              <div className="infoPost">
                <img className="infoImagem" src={pasta} alt="categoria" />
                <p className="infoTexto">{this.state.blog.category}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section >
    ) : (
      <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        <section id="SessaoPost">
          <Container>
            <Row>
              <Col lg={8}>
                <Skeleton className="titleLoading" width="100%" height="50px"></Skeleton>
              </Col>
              <Col lg={8}>
                <Skeleton className="imagemLoading" width="100%"></Skeleton>
              </Col>
            </Row>
          </Container>
        </section>
      </SkeletonTheme>
    );
  }
}

export default SessaoPost;