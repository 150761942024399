import React, { useState, useEffect } from "react";
import SessaoBanner from "../../components/sessao-banner/sessao-banner";
import SessaoSobre from "../../components/sessao-sobre/sessao-sobre";
import SessaoBlog from "../../components/sessao-blog/sessao-blog";
import SessaoProdutos from "../../components/sessao-produtos/sessao-produtos";

import "./home.scss"
import { useTitle } from "../../utilits/tittle";
import { Modal, Button, Form, Container, Col, Row, Alert } from "react-bootstrap";
import { NewsletterModal } from "./modais/question-modal";
import { NewsletterSubscribeModal } from "./modais/subscribe-modal";
import { GestorRequisicoes } from "../../services/gestor";
import { SuccessSubscriptionModal } from "./modais/subscribe-modal/success";

type NewsletterModalData = {
  image?: string,
  subscriptionTitle?: string,
  successSubscriptionTitle?: string
}

export default function Home() {
  const [showNewsletterModal, setShowNewsleterModal] = useState(false)
  const [showNewsletterSubscribeModal, setShowNewsleterSubscribeModal] = useState(false)
  const [showConfirmSubscribeModal, setShowConfirmSubscribeModel] = useState(false)
  const [data, setData] = useState<NewsletterModalData>();

  const requestInitialData = async () => {
    const response = await GestorRequisicoes.getSecaoTitulo("Guia de desinfecção Ultrapura");
    if(!response.erro && response.dados){
      const image = response.dados.data?.[0]?.section_informations?.[0]?.section_data?.imagensArray?.[0]?.src
      const subscriptionTitle = (response.dados.data?.[0]?.section_informations?.[0]?.section_data as any)?.title_subscription
      const successSubscriptionTitle = (response.dados.data?.[0]?.section_informations?.[0]?.section_data as any)?.title_success
      setData({image, subscriptionTitle, successSubscriptionTitle})
    }
  }

  useEffect(() => {
    requestInitialData();
    const dontShowAgain = localStorage.getItem("dontShowAgain")
    const subscribedOnGuide = localStorage.getItem("subscribedOnGuide")

    if (dontShowAgain && !subscribedOnGuide) {
      const dataReferencia = new Date(JSON.parse(dontShowAgain))
      const dataAtual = new Date();
      const umDiaEmMilissegundos = 86400000;

      const diferencaEmMilissegundos = dataAtual.valueOf() - dataReferencia.valueOf();
      if (diferencaEmMilissegundos >= (30 * umDiaEmMilissegundos)){
        localStorage.removeItem("dontShowAgain")
        setShowNewsleterModal(true)
      }
    } else if(!subscribedOnGuide) setShowNewsleterModal(true)
  }, [])

  const registered = () => {
    localStorage.setItem("subscribedOnGuide", JSON.stringify(true))
    setShowNewsleterSubscribeModal(false);
    setShowConfirmSubscribeModel(true)
  }

  const dontShowAgain = () => {
    localStorage.setItem("dontShowAgain", JSON.stringify(Date.now()));
    setShowNewsleterModal(false);
  }

  window.scroll(0, 0);
  useTitle("Ultrapura")
  return (

    <section id="Home">
      <SuccessSubscriptionModal 
        show={showConfirmSubscribeModal}
        onHide={() => setShowConfirmSubscribeModel(false)}
        data={data}
      />
      <NewsletterModal
        show={showNewsletterModal}
        dontShowAgain={dontShowAgain}
        subscribe={() => { setShowNewsleterModal(false); setShowNewsleterSubscribeModal(true) }}
        data={data}
        onHide={() => setShowNewsleterModal(false)}
      />
      <NewsletterSubscribeModal
        show={showNewsletterSubscribeModal}
        onHide={() => setShowNewsleterSubscribeModal(false)}
        handleClose={() => setShowNewsleterSubscribeModal(false)}
        registered={registered}
        data={data}
      />
      <SessaoBanner />
      <SessaoProdutos />
      <SessaoSobre />
      <SessaoBlog />
    </section>
  );
} 