import "./sessao-produtos.scss";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GestorRequisicoes } from "../../services/gestor";
import { CategoriaProdutoType } from "../../models/categoriasProduto";
import { FuncoesAuxilizares } from "../../utilits/funcoes-auxiliares";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../theme/color";
import { CardProduto } from "../personalizados/card-produto/card-produto";

class SessaoProdutos extends Component {
  state: {
    produtos?: Array<CategoriaProdutoType>;
  } = {};

  montaProdutos(
    produtos?: Array<CategoriaProdutoType>,
    desktop: boolean = false
  ) {
    const retorno: Array<JSX.Element> = [];

    const produtosRenderizados = produtos?.map(({ image, title }, index) => {
      if (image === undefined || title === undefined) return null;

      const cardProduto = (
        <CardProduto
          key={index}
          img={image}
          link={`/produtos/${FuncoesAuxilizares.urlNormalize(title)}`}
          titulo={title}
          verProduto={false}
          sombraMobile={false}
        />
      );

      return desktop ? (
        <Col key={index} xs={4} className="containerCard">
          {cardProduto}
        </Col>
      ) : (
        <div key={index} className="containerCard">
          {cardProduto}
        </div>
      );
    }).filter(Boolean) as JSX.Element[];

    retorno.push(...produtosRenderizados);

    return retorno;
  }

  componentDidMount() {
    GestorRequisicoes.getCategoriasProduto().then((data) => {
      this.state.produtos = data.dados;
      this.setState({ ...this.state });
    });
  }

  render(): React.ReactNode {
    //Configuração para o carrosel
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return this.state.produtos ? (
      <section id="sessaoProdutos">
        <Container>
          <Row>
            <Col className="tituloProdutosContainer">
              <h2 className="tituloProdutos">
                Produtos Para Estação <br /> de Tratamento de Água
              </h2>
            </Col>
          </Row>
          <Row className="mobile">
            <Col className="carouselContainer">
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["mobile", "tablet"]}
                showDots={true}
                dotListClass={"dotMenosDe" + this.state.produtos?.length}
              >
                {this.montaProdutos(this.state.produtos)}
              </Carousel>
            </Col>
          </Row>
          <Row className="desktop">
            {this.montaProdutos(this.state.produtos, true)}
          </Row>
        </Container>
      </section>
    ) : this.state.produtos === undefined ? (
      <SkeletonTheme
        baseColor={COLORS.skeletonBaseColor}
        highlightColor={COLORS.skeletonHighlightColor}
      >
        <section id="sessaoProdutos">
          <Container className="containerLoading">
            <Row>
              <Col lg={12}>
                <Skeleton
                  className="tituloLoading"
                  width="100%"
                  height="50px"
                ></Skeleton>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Skeleton
                  className="cardLoading"
                  width="100%"
                  height="230px"
                ></Skeleton>
              </Col>
              <Col lg={4}>
                <Skeleton
                  className="cardLoading"
                  width="100%"
                  height="230px"
                ></Skeleton>
              </Col>
              <Col lg={4}>
                <Skeleton
                  className="cardLoading"
                  width="100%"
                  height="230px"
                ></Skeleton>
              </Col>
            </Row>
          </Container>
        </section>
      </SkeletonTheme>
    ) : null;
  }
}

export default SessaoProdutos;
