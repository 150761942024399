import React from "react";
import SessaoBannerInterno from "../../components/sessao-banner-interno/sessao-banner-interno";
import SessaoSobre from "../../components/sessao-sobre/sessao-sobre";
import { useTitle } from "../../utilits/tittle";

import "./sobre.scss"

export default function Sobre() {
  window.scroll(0, 0);
  useTitle("Quem Somos")
  return (
    <section id="Sobre">
      <SessaoBannerInterno path="Quem Somos" />
      <SessaoSobre />
    </section>
  );
} 